import { FormEvent, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import i18n from '@nextroll/ar-i18n';
// @ts-ignore - no types for ar-phone-input
import PhoneInput from '@nextroll/ar-phone-input';

import {
    setPhoneFactory,
    submitFactory,
} from '../../actions/tfa-setup/SmsTFASetupActions';
import InlineFeedback from '../../components/InlineFeedback';
import { ApiError } from '../../components/errors';
import { API_STATUSES, PHONE_NUMBER_PLACEHOLDER } from '../../constants';
import SmsTFASetupContext from '../../contexts/tfa-setup/SmsTFASetupContext';

const SmsTFASetupForm = () => {
    const { state, dispatch } = useContext(SmsTFASetupContext);
    const [showPhoneError, setShowPhoneError] = useState(false);
    const navigate = useNavigate();
    const phone = state.get('phone');
    const {
        status: submitStatus,
        error: submitError,
        showError: showSubmitError,
    } = state.get('submit');
    const buttonDisabled = !phone.valid;

    const setPhone = setPhoneFactory(dispatch);
    const submit = submitFactory(dispatch, phone.value);
    const onSubmit =
        phone.valid && submitStatus !== API_STATUSES.IN_PROGRESS
            ? submit
            : undefined;
    const onFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit && onSubmit();
        return false;
    };
    const onBack = () => {
        navigate('../tfa_configure');
    };

    useEffect(() => {
        if (submitStatus === API_STATUSES.SUCCESS) {
            navigate('../validate-sms-tfa');
        }
    });

    return (
        <div className='entryhall-card-block entryhall-form-sms-tfa-setup'>
            <form id='sms_tfa_setup_form' onSubmit={onFormSubmit}>
                <input type='submit' hidden />
                <div className='entryhall-card-header'>
                    <p className='entryhall-card-header-title'>
                        {i18n.gettext(
                            'Set Up SMS for Two-Factor Authentication'
                        )}
                    </p>
                    {!showSubmitError && !showPhoneError && (
                        <>
                            <p>
                                {i18n.gettext(
                                    'We support sending Two-Factor Authentication codes via text message to your phone.'
                                )}
                            </p>
                            <p>
                                {i18n.gettext(
                                    'You must specify your full phone number, including the country code starting with a plus symbol (+).'
                                )}
                            </p>
                        </>
                    )}
                    {showSubmitError && <ApiError error={submitError} />}
                </div>
                <div className='entryhall-card-body'>
                    <PhoneInput
                        i18n={i18n}
                        classNames='entryhall-phone-number-input'
                        onChange={setPhone}
                        value={phone.value}
                        errors={phone.errors}
                        showErrors={showPhoneError}
                        label={i18n.gettext('Phone number')}
                        inputProps={{
                            autoFocus: true,
                            id: 'phone',
                            placeholder: PHONE_NUMBER_PLACEHOLDER,
                            onBlur: () =>
                                phone.value.length > 4 &&
                                setShowPhoneError(true),
                        }}
                    />
                </div>
                <div className='entryhall-card-footer'>
                    <section className='entryhall-tfa-actions'>
                        <button
                            type='button'
                            id='submit-sms-tfa-setup'
                            className='btn btn-primary'
                            disabled={buttonDisabled}
                            onClick={onSubmit}
                        >
                            {i18n.gettext('Register your number')}
                            <InlineFeedback status={submitStatus} />
                        </button>
                        <button
                            type='button'
                            className='btn btn-default'
                            onClick={onBack}
                        >
                            {i18n.gettext('Cancel')}
                        </button>
                    </section>
                </div>
            </form>
        </div>
    );
};

export default SmsTFASetupForm;
