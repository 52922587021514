import {
    ACTION_TYPES,
    TFAMethodsActions,
} from '../../actions/tfa-setup/TFAMethodsActions';
import { ImmutableTFAMethodsSetupState } from '../../contexts/tfa-setup/TFAMethodsContext';

const TFAMethodsReducer = (
    state: ImmutableTFAMethodsSetupState,
    action: TFAMethodsActions
): ImmutableTFAMethodsSetupState => {
    switch (action.type) {
        case ACTION_TYPES.TFA_METHODS_FETCH_IN_PROGRESS:
            return state
                .set('status', action.payload.status)
                .set('methods', null);
        case ACTION_TYPES.TFA_METHODS_FETCH_ERROR:
            return state
                .set('status', action.payload.status)
                .set('methods', null);
        case ACTION_TYPES.TFA_METHODS_FETCH_SUCCESS:
            return state
                .set('status', action.payload.status)
                .set('methods', action.payload.methods);
    }
};

export default TFAMethodsReducer;
