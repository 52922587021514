import {
    ACTION_TYPES,
    EmailTFASetupActions,
} from '../../actions/tfa-setup/EmailTFASetupActions';
import { ImmutableEmailTFASetupState } from '../../contexts/tfa-setup/EmailTFASetupContext';

const EmailTFASetupReducer = (
    state: ImmutableEmailTFASetupState,
    action: EmailTFASetupActions
): ImmutableEmailTFASetupState => {
    switch (action.type) {
        case ACTION_TYPES.EMAIL_TFA_SETUP_SUBMIT_IN_PROGRESS:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'error'], null)
                .setIn(['submit', 'showError'], false);
        case ACTION_TYPES.EMAIL_TFA_SETUP_SUBMIT_ERROR:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'error'], action.payload.error)
                .setIn(['submit', 'showError'], true);
        case ACTION_TYPES.EMAIL_TFA_SETUP_SUBMIT_SUCCESS:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'error'], null)
                .setIn(['submit', 'showError'], false);
    }
};

export default EmailTFASetupReducer;
