import i18n from '@nextroll/ar-i18n';

const LockedAccount = () => {
    return (
        <div className='entryhall-card-1 entryhall-locked-account'>
            <div className='entryhall-card-header'>
                <p className='entryhall-card-header-title'>
                    <i className='fas ar-icon-status-removed' />
                    <span>
                        {i18n.gettext(
                            'Multiple Failed Login Attempts have been made'
                        )}
                    </span>
                </p>
            </div>
            <div className='entryhall-card-body'>
                <p>
                    {i18n.gettext(
                        'Your account has been locked, you can wait for it to automatically unlock in 30 minutes.'
                    )}
                </p>
                <p>
                    {i18n.gettext(
                        'If your account exists, please review the instructions just sent to your registered email.'
                    )}
                </p>
            </div>
        </div>
    );
};

export default LockedAccount;
