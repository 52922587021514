import { Dispatch } from 'react';

import { adrollApi } from '../../services/adroll';
import { ActionMap } from '../../typings';
import {
    ISetFetchErrorPayload,
    ISetFetchInProgressPayload,
    ISetFetchSuccessPayload,
    ISetInputValuePayload,
    setFetchError,
    setFetchInProgress,
    setFetchSuccess,
    setInputValue,
} from '../common';

export enum ACTION_TYPES {
    SMS_TFA_SETUP_VALIDATION_SET_CODE = 'SMS_TFA_SETUP_VALIDATION_SET_CODE',
    SMS_TFA_SETUP_VALIDATION_SUBMIT_IN_PROGRESS = 'SMS_TFA_SETUP_VALIDATION_SUBMIT_IN_PROGRESS',
    SMS_TFA_SETUP_VALIDATION_SUBMIT_SUCCESS = 'SMS_TFA_SETUP_VALIDATION_SUBMIT_SUCCESS',
    SMS_TFA_SETUP_VALIDATION_SUBMIT_ERROR = 'SMS_TFA_SETUP_VALIDATION_SUBMIT_ERROR',
    SMS_TFA_SETUP_VALIDATION_RESEND_SMS_SUBMIT_IN_PROGRESS = 'SMS_TFA_SETUP_VALIDATION_RESEND_SMS_SUBMIT_IN_PROGRESS',
    SMS_TFA_SETUP_VALIDATION_RESEND_SMS_SUBMIT_SUCCESS = 'SMS_TFA_SETUP_VALIDATION_RESEND_SMS_SUBMIT_SUCCESS',
    SMS_TFA_SETUP_VALIDATION_RESEND_SMS_SUBMIT_ERROR = 'SMS_TFA_SETUP_VALIDATION_RESEND_SMS_SUBMIT_ERROR',
}

type SmsTFASetupValidationPayloads = {
    [ACTION_TYPES.SMS_TFA_SETUP_VALIDATION_SET_CODE]: ISetInputValuePayload;
    [ACTION_TYPES.SMS_TFA_SETUP_VALIDATION_SUBMIT_IN_PROGRESS]: ISetFetchInProgressPayload;
    [ACTION_TYPES.SMS_TFA_SETUP_VALIDATION_SUBMIT_SUCCESS]: ISetFetchSuccessPayload;
    [ACTION_TYPES.SMS_TFA_SETUP_VALIDATION_SUBMIT_ERROR]: ISetFetchErrorPayload;
    [ACTION_TYPES.SMS_TFA_SETUP_VALIDATION_RESEND_SMS_SUBMIT_IN_PROGRESS]: ISetFetchInProgressPayload;
    [ACTION_TYPES.SMS_TFA_SETUP_VALIDATION_RESEND_SMS_SUBMIT_SUCCESS]: ISetFetchSuccessPayload;
    [ACTION_TYPES.SMS_TFA_SETUP_VALIDATION_RESEND_SMS_SUBMIT_ERROR]: ISetFetchErrorPayload;
};

export type SmsTFASetupValidationActions =
    ActionMap<SmsTFASetupValidationPayloads>[keyof ActionMap<SmsTFASetupValidationPayloads>];

export const setCodeFactory =
    (dispatchFn: Dispatch<SmsTFASetupValidationActions>) => (event: any) => {
        dispatchFn(
            setInputValue(
                ACTION_TYPES.SMS_TFA_SETUP_VALIDATION_SET_CODE,
                event.target.value,
                () => [true, null]
            )
        );
    };

export const resendSMSFactory =
    (dispatchFn: Dispatch<SmsTFASetupValidationActions>) => async () => {
        dispatchFn(
            setFetchInProgress(
                ACTION_TYPES.SMS_TFA_SETUP_VALIDATION_RESEND_SMS_SUBMIT_IN_PROGRESS
            )
        );
        try {
            await adrollApi.post('entryhall/send_sms_tfa', {}, {});
            dispatchFn(
                setFetchSuccess(
                    ACTION_TYPES.SMS_TFA_SETUP_VALIDATION_RESEND_SMS_SUBMIT_SUCCESS
                )
            );
        } catch (error) {
            dispatchFn(
                setFetchError(
                    ACTION_TYPES.SMS_TFA_SETUP_VALIDATION_RESEND_SMS_SUBMIT_ERROR,
                    error
                )
            );
        }
    };

export const submitFactory =
    (dispatchFn: Dispatch<SmsTFASetupValidationActions>, token: string) =>
    async () => {
        dispatchFn(
            setFetchInProgress(
                ACTION_TYPES.SMS_TFA_SETUP_VALIDATION_SUBMIT_IN_PROGRESS
            )
        );
        try {
            await adrollApi.post(
                'entryhall/validate_sms_tfa_setup',
                {},
                { token }
            );
            dispatchFn(
                setFetchSuccess(
                    ACTION_TYPES.SMS_TFA_SETUP_VALIDATION_SUBMIT_SUCCESS
                )
            );
        } catch (error) {
            dispatchFn(
                setFetchError(
                    ACTION_TYPES.SMS_TFA_SETUP_VALIDATION_SUBMIT_ERROR,
                    error
                )
            );
        }
    };
