import { FormEvent, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import i18n from '@nextroll/ar-i18n';
// @ts-ignore - no types for ar-style-base
import { InputGroup } from '@nextroll/ar-style-base';

import {
    setEmailFactory,
    setNextFactory,
    submitFactory,
} from '../../actions/sign-in/SamlSignInActions';
import FormSpinner from '../../components/FormSpinner';
import InlineFeedback from '../../components/InlineFeedback';
import { ApiError } from '../../components/errors';
import { API_STATUSES } from '../../constants';
import ThemeWrapperContext from '../../contexts/ThemeWrapperContext';
import SignInContext from '../../contexts/sign-in/SamlSignInContext';
import { ErrorId } from '../../errors';

const SamlSignInForm = () => {
    const { state, dispatch } = useContext(SignInContext);
    const { supportEmail } = useContext(ThemeWrapperContext);
    const [redirectSpinner, setRedirectSpinner] = useState(false);
    const navigate = useNavigate();
    const search = useLocation().search;
    const email = state.get('email');
    const next = state.get('next');
    const {
        status: submitStatus,
        error: submitError,
        showError: showSubmitError,
        redirect: submitRedirect,
        to: submitRedirectTo,
    } = state.get('submit');

    useEffect(() => {
        if (submitStatus === API_STATUSES.SUCCESS) {
            if (submitRedirect === 'internal_redirect') {
                navigate(`../${submitRedirectTo}`);
            } else if (submitRedirect === 'external_redirect') {
                setRedirectSpinner(true);
                window.location.href = submitRedirectTo;
            }
        } else if (submitStatus === API_STATUSES.ERROR) {
            if (submitError.errorId === ErrorId.user_locked) {
                navigate(`../lockedaccount`);
            }
        }
    }, [state.get('submit')]);

    const setEmail = setEmailFactory(dispatch);
    const submit = submitFactory(dispatch, email.value, next);
    const setNext = setNextFactory(dispatch);
    const { email: urlEmail, next: urlNext } = Object.fromEntries(
        useSearchParams()[0]
    );
    useEffect(() => {
        if (urlEmail) {
            const ev = { target: { value: urlEmail } };
            setEmail(ev);
        }
        if (urlNext) {
            setNext(urlNext);
        }
    }, []);

    const buttonDisabled = !email.valid;
    const onSubmit =
        submitStatus !== API_STATUSES.IN_PROGRESS ? submit : undefined;
    const onFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit && onSubmit();
        return false;
    };

    if (redirectSpinner) {
        return <FormSpinner />;
    }
    return (
        <div className='entryhall-card-block entryhall-form-saml-sign-in'>
            <form id='saml_sign_in_form' onSubmit={onFormSubmit}>
                <input type='submit' hidden />
                <div className='entryhall-card-header'>
                    {!showSubmitError && (
                        <h3 className='entryhall-card-header-title'>
                            {i18n.gettext('Sign In with Single Sign-On')}
                        </h3>
                    )}
                    {showSubmitError && (
                        <ApiError
                            error={submitError}
                            supportEmailAddress={supportEmail}
                        />
                    )}
                </div>
                <div className='entryhall-card-body'>
                    <InputGroup
                        i18n={i18n}
                        classNames='entryhall-signin-email-input'
                        label={i18n.gettext('Email Address')}
                        inputProps={{
                            autoFocus: true,
                            value: email.value,
                            onChange: setEmail,
                            name: 'username',
                        }}
                        validationState={
                            showSubmitError ? InputGroup.VALIDATION_ERROR : ''
                        }
                    />
                </div>
                <div className='entryhall-card-footer'>
                    <button
                        type='button'
                        className='btn btn-primary btn-block'
                        disabled={buttonDisabled}
                        onClick={onSubmit}
                    >
                        {i18n.gettext('Sign In')}
                        <InlineFeedback status={submitStatus} />
                    </button>
                    <div className='entryhall-or-separator'>
                        <span>{i18n.gettext('Or')}</span>
                    </div>
                    <button
                        type='button'
                        className='btn btn-default entryhall-grey-button'
                        onClick={() => navigate(`../signin${search}`)}
                    >
                        {i18n.gettext('Sign In with Email and Password')}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default SamlSignInForm;
