import i18n from '@nextroll/ar-i18n';

const ToSLink = () => (
    <a
        href='https://www.nextroll.com/terms'
        target='_blank'
        rel='noopener noreferrer'
    >
        {i18n.gettext('Terms of Service')}
    </a>
);

const PrivacyLink = () => (
    <a
        href='https://www.nextroll.com/privacy'
        target='_blank'
        rel='noopener noreferrer'
    >
        {i18n.gettext('Privacy Notice')}
    </a>
);

const AdChoicesLink = () => (
    <a
        href='https://app.adroll.com/optout/'
        target='_blank'
        rel='noopener noreferrer'
    >
        <img
            src='https://s.adroll.com/j/ad-choices-small.png'
            className='entryhall-footer-link-ad-preference-image'
            role='presentation'
            referrerPolicy='no-referrer'
        />
        {i18n.gettext('Adjust Ad Preferences')}
    </a>
);

const CopyrightMessage = () => (
    <p>
        {i18n.gettext(
            'Copyright © {{currentFullYear}} NextRoll, Inc. All rights reserved.',
            {
                currentFullYear: new Date().getFullYear(),
            }
        )}
    </p>
);

const Footer = () => {
    return (
        <div className='entryhall-footer'>
            <div className='entryhall-footer-links'>
                <ToSLink />
                <PrivacyLink />
                <AdChoicesLink />
            </div>

            <div className='entryhall-footer-copyright'>
                <CopyrightMessage />
            </div>
        </div>
    );
};

export default Footer;
