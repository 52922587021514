import { createContext } from 'react';

import { SupportedBUs } from '../constants';

interface IThemeWrapperContextValue {
    product: string;
    businessUnit: SupportedBUs;
    supportEmail: string;
}

const defaultValue: IThemeWrapperContextValue = {
    product: '',
    businessUnit: '',
    supportEmail: '',
};

const ThemeWrapperContext =
    createContext<IThemeWrapperContextValue>(defaultValue);

export default ThemeWrapperContext;
