import {
    ACTION_TYPES,
    SmsTFASetupActions,
} from '../../actions/tfa-setup/SmsTFASetupActions';
import { ImmutableSmsTFASetupState } from '../../contexts/tfa-setup/SmsTFASetupContext';

const SmsTFASetupReducer = (
    state: ImmutableSmsTFASetupState,
    action: SmsTFASetupActions
): ImmutableSmsTFASetupState => {
    switch (action.type) {
        case ACTION_TYPES.SMS_TFA_SETUP_SET_PHONE:
            return state
                .set('phone', action.payload)
                .setIn(['submit', 'showError'], false);
        case ACTION_TYPES.SMS_TFA_SETUP_SUBMIT_IN_PROGRESS:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'error'], null)
                .setIn(['submit', 'showError'], false);
        case ACTION_TYPES.SMS_TFA_SETUP_SUBMIT_ERROR:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'error'], action.payload.error)
                .setIn(['submit', 'showError'], true);
        case ACTION_TYPES.SMS_TFA_SETUP_SUBMIT_SUCCESS:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'error'], null)
                .setIn(['submit', 'showError'], false);
    }
};

export default SmsTFASetupReducer;
