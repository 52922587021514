import { Map } from 'immutable';
import { createContext } from 'react';

import { EmailTFASetupActions } from '../../actions/tfa-setup/EmailTFASetupActions';
import { API_STATUSES } from '../../constants';
import { IApiError } from '../../errors';
import { ImmutableMap } from '../../typings';

export interface IEmailTFASetupState {
    submit: {
        status: string;
        error: IApiError;
        showError: boolean;
    };
}

export type ImmutableEmailTFASetupState = ImmutableMap<IEmailTFASetupState>;

interface IEmailTFASetupContextValue {
    state: ImmutableEmailTFASetupState;
    dispatch: React.Dispatch<EmailTFASetupActions>;
}

const initialState: IEmailTFASetupState = {
    submit: {
        status: API_STATUSES.IDLE,
        error: null,
        showError: false,
    },
};

export const INITIAL_EMAIL_TFA_SETUP_STATE = Map(initialState);

const defaultValue: IEmailTFASetupContextValue = {
    state: INITIAL_EMAIL_TFA_SETUP_STATE,
    dispatch: () => null,
};

const EmailTFASetupContext =
    createContext<IEmailTFASetupContextValue>(defaultValue);

export default EmailTFASetupContext;
