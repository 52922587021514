import { adrollApi } from '../../services/adroll';
import { ActionMap } from '../../typings';
import {
    ISetFetchErrorPayload,
    ISetFetchInProgressPayload,
    ISetFetchSuccessPayload,
    ISetInputValuePayload,
    setFetchError,
    setFetchInProgress,
    setFetchSuccess,
    setInputValue,
} from '../common';

export enum ACTION_TYPES {
    PASSWORD_RESET_SAVE_SET_PARAMS = 'PASSWORD_RESET_SAVE_SET_PARAMS',
    PASSWORD_RESET_SAVE_SET_PASSWORD = 'PASSWORD_RESET_SAVE_SET_PASSWORD',
    PASSWORD_RESET_SAVE_SET_PASSWORD_CONFIRMATION = 'PASSWORD_RESET_SAVE_SET_PASSWORD_CONFIRMATION',
    PASSWORD_RESET_SAVE_SUBMIT_IN_PROGRESS = 'PASSWORD_RESET_SAVE_SUBMIT_IN_PROGRESS',
    PASSWORD_RESET_SAVE_SUBMIT_SUCCESS = 'PASSWORD_RESET_SAVE_SUBMIT_SUCCESS',
    PASSWORD_RESET_SAVE_SUBMIT_ERROR = 'PASSWORD_RESET_SAVE_SUBMIT_ERROR',
}

type PasswordResetSavePayloads = {
    [ACTION_TYPES.PASSWORD_RESET_SAVE_SET_PARAMS]: {
        token: string | undefined;
        email: string | undefined;
        valid: string | undefined;
    };
    [ACTION_TYPES.PASSWORD_RESET_SAVE_SET_PASSWORD]: ISetInputValuePayload;
    [ACTION_TYPES.PASSWORD_RESET_SAVE_SET_PASSWORD_CONFIRMATION]: ISetInputValuePayload;
    [ACTION_TYPES.PASSWORD_RESET_SAVE_SUBMIT_IN_PROGRESS]: ISetFetchInProgressPayload;
    [ACTION_TYPES.PASSWORD_RESET_SAVE_SUBMIT_SUCCESS]: ISetFetchSuccessPayload;
    [ACTION_TYPES.PASSWORD_RESET_SAVE_SUBMIT_ERROR]: ISetFetchErrorPayload;
};

export type PasswordResetSaveActions =
    ActionMap<PasswordResetSavePayloads>[keyof ActionMap<PasswordResetSavePayloads>];

export const setParams = (
    dispatchFn: React.Dispatch<PasswordResetSaveActions>,
    token: string | undefined,
    email: string | undefined,
    valid: string | undefined
) => {
    dispatchFn({
        type: ACTION_TYPES.PASSWORD_RESET_SAVE_SET_PARAMS,
        payload: {
            token,
            email,
            valid,
        },
    });
};

export const setPasswordFactory =
    (dispatchFn: React.Dispatch<PasswordResetSaveActions>) =>
    (event: any, valid: boolean, errors: string[]) => {
        dispatchFn(
            setInputValue(
                ACTION_TYPES.PASSWORD_RESET_SAVE_SET_PASSWORD,
                event.target.value,
                () => [valid, errors]
            )
        );
    };

export const setPasswordConfirmationFactory =
    (dispatchFn: React.Dispatch<PasswordResetSaveActions>) =>
    (event: any, valid: boolean, errors: string[]) => {
        dispatchFn(
            setInputValue(
                ACTION_TYPES.PASSWORD_RESET_SAVE_SET_PASSWORD_CONFIRMATION,
                event.target.value,
                () => [valid, errors]
            )
        );
    };

export const submitFactory =
    (
        dispatchFn: React.Dispatch<PasswordResetSaveActions>,
        email: string,
        token: string,
        password: string,
        passwordConfirmation: string
    ) =>
    async () => {
        dispatchFn(
            setFetchInProgress(
                ACTION_TYPES.PASSWORD_RESET_SAVE_SUBMIT_IN_PROGRESS
            )
        );
        try {
            await adrollApi.post(
                'entryhall/save_password_reset',
                {},
                {
                    email,
                    token,
                    new_password: password,
                    password_confirmation: passwordConfirmation,
                }
            );
            dispatchFn(
                setFetchSuccess(ACTION_TYPES.PASSWORD_RESET_SAVE_SUBMIT_SUCCESS)
            );
        } catch (error) {
            dispatchFn(
                setFetchError(
                    ACTION_TYPES.PASSWORD_RESET_SAVE_SUBMIT_ERROR,
                    error
                )
            );
        }
    };
