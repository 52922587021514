import { useReducer } from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import SamlSignInContext, {
    INITIAL_SAML_SIGN_IN_FORM_STATE,
} from '../../contexts/sign-in/SamlSignInContext';
import SamlSignInForm from '../../forms/sign-in/SamlSignInForm';
import SamlSignInReducer from '../../reducers/sign-in/SamlSignInReducer';

const SamlSignInPage = () => {
    const [state, dispatch] = useReducer(
        SamlSignInReducer,
        INITIAL_SAML_SIGN_IN_FORM_STATE
    );

    return (
        <SamlSignInContext.Provider value={{ state, dispatch }}>
            <section className='entryhall-page-sign-in'>
                <Header />
                <SamlSignInForm />
                <Footer />
            </section>
        </SamlSignInContext.Provider>
    );
};

export default SamlSignInPage;
