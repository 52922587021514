import { Dispatch } from 'react';

import { API_STATUSES } from '../../constants';
import { adrollApi } from '../../services/adroll';
import { ActionMap } from '../../typings';
import {
    ISetFetchErrorPayload,
    ISetFetchInProgressPayload,
    setFetchError,
    setFetchInProgress,
} from '../common';

export enum ACTION_TYPES {
    TFA_METHODS_FETCH_IN_PROGRESS = 'TFA_METHODS_FETCH_IN_PROGRESS',
    TFA_METHODS_FETCH_SUCCESS = 'TFA_METHODS_FETCH_SUCCESS',
    TFA_METHODS_FETCH_ERROR = 'TFA_METHODS_FETCH_ERROR',
}

export interface ISetFetchTFAMethodsSuccessPayload {
    status: API_STATUSES;
    methods: string[];
}

type TFAMethodsPayloads = {
    [ACTION_TYPES.TFA_METHODS_FETCH_IN_PROGRESS]: ISetFetchInProgressPayload;
    [ACTION_TYPES.TFA_METHODS_FETCH_SUCCESS]: ISetFetchTFAMethodsSuccessPayload;
    [ACTION_TYPES.TFA_METHODS_FETCH_ERROR]: ISetFetchErrorPayload;
};

export type TFAMethodsActions =
    ActionMap<TFAMethodsPayloads>[keyof ActionMap<TFAMethodsPayloads>];

const setFetchTFAMethodsSuccess = (
    tfa_methods: string[]
): TFAMethodsActions => {
    return {
        type: ACTION_TYPES.TFA_METHODS_FETCH_SUCCESS,
        payload: {
            status: API_STATUSES.SUCCESS,
            methods: tfa_methods,
        },
    };
};

export const fetchTFAMethodsFactory =
    (dispatchFn: Dispatch<TFAMethodsActions>) => async () => {
        dispatchFn(
            setFetchInProgress(ACTION_TYPES.TFA_METHODS_FETCH_IN_PROGRESS)
        );
        try {
            const res = await adrollApi.get(
                'entryhall/get_enabled_tfa_methods'
            );
            dispatchFn(setFetchTFAMethodsSuccess(res.methods));
        } catch (error) {
            dispatchFn(
                setFetchError(ACTION_TYPES.TFA_METHODS_FETCH_ERROR, error)
            );
        }
    };
