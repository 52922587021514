import { useReducer } from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import TFAMethodsContext, {
    INITIAL_TFA_METHODS_STATE,
} from '../../contexts/tfa-setup/TFAMethodsContext';
import TFAMethodsForm from '../../forms/tfa-setup/TFAMethodsForm';
import TFAMethodsReducer from '../../reducers/tfa-setup/TFAMethodsReducer';

const TFAMethodsPage = () => {
    const [state, dispatch] = useReducer(
        TFAMethodsReducer,
        INITIAL_TFA_METHODS_STATE
    );

    return (
        <TFAMethodsContext.Provider value={{ state, dispatch }}>
            <div className='entryhall-page-tfa-methods'>
                <Header />
                <TFAMethodsForm />
                <Footer />
            </div>
        </TFAMethodsContext.Provider>
    );
};

export default TFAMethodsPage;
