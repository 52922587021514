import {
    ACTION_TYPES,
    PasswordResetSaveActions,
} from '../../actions/password-reset/PasswordResetSaveActions';
import { ImmutablePasswordResetSaveState } from '../../contexts/password-reset/PasswordResetSaveContext';

const PasswordResetSaveReducer = (
    state: ImmutablePasswordResetSaveState,
    action: PasswordResetSaveActions
): ImmutablePasswordResetSaveState => {
    switch (action.type) {
        case ACTION_TYPES.PASSWORD_RESET_SAVE_SET_PARAMS:
            return state.set('urlParams', action.payload);
        case ACTION_TYPES.PASSWORD_RESET_SAVE_SET_PASSWORD:
            return state
                .set('password', action.payload)
                .setIn(['submit', 'showError'], false);
        case ACTION_TYPES.PASSWORD_RESET_SAVE_SET_PASSWORD_CONFIRMATION:
            return state
                .set('passwordConfirmation', action.payload)
                .setIn(['submit', 'showError'], false);
        case ACTION_TYPES.PASSWORD_RESET_SAVE_SUBMIT_IN_PROGRESS:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'error'], null)
                .setIn(['submit', 'showError'], false);

        case ACTION_TYPES.PASSWORD_RESET_SAVE_SUBMIT_ERROR:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'error'], action.payload.error)
                .setIn(['submit', 'showError'], true);

        case ACTION_TYPES.PASSWORD_RESET_SAVE_SUBMIT_SUCCESS:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'error'], null)
                .setIn(['submit', 'showError'], false);
    }
};

export default PasswordResetSaveReducer;
