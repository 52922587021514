import { Dispatch } from 'react';

import { API_STATUSES } from '../../constants';
import { reportApiError } from '../../errors';
import { adrollApi } from '../../services/adroll';
import { ActionMap } from '../../typings';
import {
    ISetFetchErrorPayload,
    ISetFetchInProgressPayload,
    ISetInputValuePayload,
    setFetchError,
    setFetchInProgress,
    setInputValue,
} from '../common';

export enum ACTION_TYPES {
    SIGN_IN_SET_EMAIL = 'SIGN_IN_SET_EMAIL',
    SIGN_IN_SET_PASSWORD = 'SIGN_IN_SET_PASSWORD',
    SIGN_IN_SET_NEXT = 'SIGN_IN_SET_NEXT',
    SIGN_IN_SUBMIT_IN_PROGRESS = 'SIGN_IN_SUBMIT_IN_PROGRESS',
    SIGN_IN_SUBMIT_SUCCESS = 'SIGN_IN_SUBMIT_SUCCESS',
    SIGN_IN_SUBMIT_ERROR = 'SIGN_IN_SUBMIT_ERROR',
}

export interface ISetNextPayload {
    value: string;
}

export interface ISetFetchSuccessPayload {
    status: API_STATUSES;
    redirect: string;
    to: string;
}

type SignInPayloads = {
    [ACTION_TYPES.SIGN_IN_SET_EMAIL]: ISetInputValuePayload;
    [ACTION_TYPES.SIGN_IN_SET_PASSWORD]: ISetInputValuePayload;
    [ACTION_TYPES.SIGN_IN_SET_NEXT]: ISetNextPayload;
    [ACTION_TYPES.SIGN_IN_SUBMIT_IN_PROGRESS]: ISetFetchInProgressPayload;
    [ACTION_TYPES.SIGN_IN_SUBMIT_SUCCESS]: ISetFetchSuccessPayload;
    [ACTION_TYPES.SIGN_IN_SUBMIT_ERROR]: ISetFetchErrorPayload;
};

export type SignInActions =
    ActionMap<SignInPayloads>[keyof ActionMap<SignInPayloads>];

export const setEmailFactory =
    (dispatchFn: Dispatch<SignInActions>) => (event: any) => {
        dispatchFn(
            setInputValue(
                ACTION_TYPES.SIGN_IN_SET_EMAIL,
                event.target.value,
                () => [true, null]
            )
        );
    };

export const setPasswordFactory =
    (dispatchFn: Dispatch<SignInActions>) => (event: any) => {
        dispatchFn(
            setInputValue(
                ACTION_TYPES.SIGN_IN_SET_PASSWORD,
                event.target.value,
                () => [true, null]
            )
        );
    };

export const setNextFactory =
    (dispatchFn: Dispatch<SignInActions>) => (next: string) => {
        dispatchFn({
            type: ACTION_TYPES.SIGN_IN_SET_NEXT,
            payload: {
                value: next,
            },
        });
    };

const setSignInSubmitSuccess = (response: any): SignInActions => {
    return {
        type: ACTION_TYPES.SIGN_IN_SUBMIT_SUCCESS,
        payload: {
            status: API_STATUSES.SUCCESS,
            redirect: response.type,
            to: response.action,
        },
    };
};

export const submitFactory =
    (
        dispatchFn: Dispatch<SignInActions>,
        email: string,
        password: string,
        next: string
    ) =>
    async () => {
        dispatchFn(setFetchInProgress(ACTION_TYPES.SIGN_IN_SUBMIT_IN_PROGRESS));

        try {
            const res = await adrollApi.post(
                'entryhall/signin',
                {},
                {
                    uname: email,
                    password,
                    next,
                }
            );
            dispatchFn(setSignInSubmitSuccess(res));
        } catch (error) {
            reportApiError(error);
            dispatchFn(setFetchError(ACTION_TYPES.SIGN_IN_SUBMIT_ERROR, error));
        }
    };
