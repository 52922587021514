import PropTypes from 'prop-types';

import {
    BUSINESS_UNIT_ADROLL,
    BUSINESS_UNIT_ROLLWORKS,
    ThemeLoader, // @ts-ignore - no types for ar-style-base
} from '@nextroll/ar-style-base';

import { SUPPORT_EMAIL_ADDRESS, SupportedBUs } from '../constants';
import ThemeWrapperContext from '../contexts/ThemeWrapperContext';

interface IProps {
    children: React.ReactChild;
}

// ThemeLoader-related constants.
const CSS_FILES = {
    [BUSINESS_UNIT_ADROLL]: 'entryhall-style-adroll.css',
    [BUSINESS_UNIT_ROLLWORKS]: 'entryhall-style-rollworks.css',
};
const STATIC_PATH = process.env.STATIC_PATH;

const ThemeWrapper = ({ children }: IProps) => {
    const product = new URLSearchParams(window.location.search).get('product');
    const businessUnit: SupportedBUs =
        product === 'b2b' ? BUSINESS_UNIT_ROLLWORKS : BUSINESS_UNIT_ADROLL;
    const supportEmail = SUPPORT_EMAIL_ADDRESS[businessUnit];

    return (
        <ThemeWrapperContext.Provider
            value={{ product, businessUnit, supportEmail }}
        >
            <ThemeLoader
                advertisable={{
                    business_unit: businessUnit,
                    is_b2b: product === 'b2b',
                }}
                cssFiles={CSS_FILES}
                staticPath={STATIC_PATH}
            >
                {children}
            </ThemeLoader>
        </ThemeWrapperContext.Provider>
    );
};

ThemeWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ThemeWrapper;
