import { Map } from 'immutable';
import React, { createContext } from 'react';

import { ISetInputValuePayload } from '../../actions/common';
import { AppTFASetupActions } from '../../actions/tfa-setup/AppTFASetupActions';
import { API_STATUSES } from '../../constants';
import { IApiError } from '../../errors';
import { ImmutableMap } from '../../typings';

interface IOTPState extends ISetInputValuePayload {
    value: string;
}

export interface IAppTFASetupState {
    qr_code: {
        status: string;
        code: string;
    };
    otp: IOTPState;
    submission: {
        status: string;
        showError: boolean;
        error: IApiError;
    };
}

export type ImmutableAppTFASetupState = ImmutableMap<IAppTFASetupState>;

interface IAppTFASetupContextValue {
    state: ImmutableAppTFASetupState;
    dispatch: React.Dispatch<AppTFASetupActions>;
}

const initialState: IAppTFASetupState = {
    qr_code: {
        status: API_STATUSES.IDLE,
        code: '',
    },
    otp: {
        value: '',
        valid: false,
        error: null,
    },
    submission: {
        status: API_STATUSES.IDLE,
        showError: false,
        error: null,
    },
};

export const INITIAL_APP_TFA_SETUP_STATE = Map(initialState);

const defaultValue: IAppTFASetupContextValue = {
    state: INITIAL_APP_TFA_SETUP_STATE,
    dispatch: () => {},
};

const AppTFASetupContext =
    createContext<IAppTFASetupContextValue>(defaultValue);

export default AppTFASetupContext;
