import { useReducer } from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import SmsTFASetupContext, {
    INITIAL_SMS_TFA_SETUP_FORM_STATE,
} from '../../contexts/tfa-setup/SmsTFASetupContext';
import SmsTFASetupForm from '../../forms/tfa-setup/SmsTFASetupForm';
import SmsTFASetupReducer from '../../reducers/tfa-setup/SmsTFASetupReducer';

const SmsTFASetupPage = () => {
    const [state, dispatch] = useReducer(
        SmsTFASetupReducer,
        INITIAL_SMS_TFA_SETUP_FORM_STATE
    );

    return (
        <SmsTFASetupContext.Provider value={{ state, dispatch }}>
            <section className='entryhall-page-sms-tfa-setup'>
                <Header />
                <SmsTFASetupForm />
                <Footer />
            </section>
        </SmsTFASetupContext.Provider>
    );
};

export default SmsTFASetupPage;
