import {
    ACTION_TYPES,
    PasswordExpiredActions,
} from '../../actions/password-expired/PasswordExpiredActions';
import { ImmutablePasswordExpiredState } from '../../contexts/password-expired/PasswordExpiredContext';

const PasswordExpiredReducer = (
    state: ImmutablePasswordExpiredState,
    action: PasswordExpiredActions
): ImmutablePasswordExpiredState => {
    switch (action.type) {
        case ACTION_TYPES.PASSWORD_EXPIRED_SET_CURRENT_PASSWORD:
            return state
                .set('currentPassword', action.payload)
                .setIn(['submit', 'showError'], false);
        case ACTION_TYPES.PASSWORD_EXPIRED_SET_PASSWORD:
            return state
                .set('password', action.payload)
                .setIn(['submit', 'showError'], false);
        case ACTION_TYPES.PASSWORD_EXPIRED_SET_PASSWORD_CONFIRMATION:
            return state
                .set('passwordConfirmation', action.payload)
                .setIn(['submit', 'showError'], false);
        case ACTION_TYPES.PASSWORD_EXPIRED_SUBMIT_IN_PROGRESS:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'error'], null)
                .setIn(['submit', 'showError'], false);

        case ACTION_TYPES.PASSWORD_EXPIRED_SUBMIT_ERROR:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'error'], action.payload.error)
                .setIn(['submit', 'showError'], true);

        case ACTION_TYPES.PASSWORD_EXPIRED_SUBMIT_SUCCESS:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'error'], null)
                .setIn(['submit', 'showError'], false);
    }
};

export default PasswordExpiredReducer;
