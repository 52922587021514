import { Dispatch } from 'react';

// @ts-ignore - no types for ar-phone-input
import { ERRORS as PHONE_ERRORS } from '@nextroll/ar-phone-input';

import { IPhoneNumberValuePayload } from '../../contexts/tfa-setup/SmsTFASetupContext';
import { adrollApi } from '../../services/adroll';
import { ActionMap } from '../../typings';
import {
    ISetFetchErrorPayload,
    ISetFetchInProgressPayload,
    ISetFetchSuccessPayload,
    setFetchError,
    setFetchInProgress,
    setFetchSuccess,
} from '../common';

export enum ACTION_TYPES {
    SMS_TFA_SETUP_SET_PHONE = 'SMS_TFA_SETUP_SET_PHONE',
    SMS_TFA_SETUP_SUBMIT_IN_PROGRESS = 'SMS_TFA_SETUP_SUBMIT_IN_PROGRESS',
    SMS_TFA_SETUP_SUBMIT_SUCCESS = 'SMS_TFA_SETUP_SUBMIT_SUCCESS',
    SMS_TFA_SETUP_SUBMIT_ERROR = 'SMS_TFA_SETUP_SUBMIT_ERROR',
}

type SmsTFASetupPayloads = {
    [ACTION_TYPES.SMS_TFA_SETUP_SET_PHONE]: IPhoneNumberValuePayload;
    [ACTION_TYPES.SMS_TFA_SETUP_SUBMIT_IN_PROGRESS]: ISetFetchInProgressPayload;
    [ACTION_TYPES.SMS_TFA_SETUP_SUBMIT_SUCCESS]: ISetFetchSuccessPayload;
    [ACTION_TYPES.SMS_TFA_SETUP_SUBMIT_ERROR]: ISetFetchErrorPayload;
};

export type SmsTFASetupActions =
    ActionMap<SmsTFASetupPayloads>[keyof ActionMap<SmsTFASetupPayloads>];

export const setPhoneFactory =
    (dispatchFn: Dispatch<SmsTFASetupActions>) =>
    (newValue: string, newIsValid: boolean, newErrors: React.ReactNode[]) => {
        dispatchFn({
            type: ACTION_TYPES.SMS_TFA_SETUP_SET_PHONE,
            payload: {
                value: newValue,
                valid: newIsValid,
                errors: newErrors.map((e) => PHONE_ERRORS.ERROR_MESSAGES[e]),
            },
        });
    };

export const submitFactory =
    (dispatchFn: Dispatch<SmsTFASetupActions>, phone: string) => async () => {
        dispatchFn(
            setFetchInProgress(ACTION_TYPES.SMS_TFA_SETUP_SUBMIT_IN_PROGRESS)
        );
        try {
            await adrollApi.post('entryhall/setup_sms_tfa', {}, { phone });
            dispatchFn(
                setFetchSuccess(ACTION_TYPES.SMS_TFA_SETUP_SUBMIT_SUCCESS)
            );
        } catch (error) {
            dispatchFn(
                setFetchError(ACTION_TYPES.SMS_TFA_SETUP_SUBMIT_ERROR, error)
            );
        }
    };
