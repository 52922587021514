import { useReducer } from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import PasswordResetInitConfirmation from '../../components/password-reset/PasswordResetInitConfirmation';
import { API_STATUSES } from '../../constants';
import PasswordResetInitContext, {
    INITIAL_PASSWORD_RESET_INIT_FORM_STATE,
} from '../../contexts/password-reset/PasswordResetInitContext';
import PasswordResetInitForm from '../../forms/password-reset/PasswordResetInitForm';
import PasswordResetInitReducer from '../../reducers/password-reset/PasswordResetInitReducer';

const PasswordResetInitPage = () => {
    const [state, dispatch] = useReducer(
        PasswordResetInitReducer,
        INITIAL_PASSWORD_RESET_INIT_FORM_STATE
    );

    const submitSuccess = state.get('submit').status === API_STATUSES.SUCCESS;

    return (
        <PasswordResetInitContext.Provider value={{ state, dispatch }}>
            <section className='entryhall-page-password-reset-init'>
                <Header />
                {!submitSuccess && <PasswordResetInitForm />}
                {submitSuccess && <PasswordResetInitConfirmation />}
                <Footer />
            </section>
        </PasswordResetInitContext.Provider>
    );
};

export default PasswordResetInitPage;
