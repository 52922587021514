import { useContext, useState } from 'react';

import i18n from '@nextroll/ar-i18n';
// @ts-ignore - no types for ar-style-base
import { InputGroup } from '@nextroll/ar-style-base';

import {
    setEmailFactory,
    submitFactory,
} from '../../actions/password-reset/PasswordResetInitActions';
import InlineFeedback from '../../components/InlineFeedback';
import { ApiError } from '../../components/errors';
import { API_STATUSES } from '../../constants';
import PasswordResetInitContext from '../../contexts/password-reset/PasswordResetInitContext';

const PasswordResetInitForm = () => {
    const [showValidationError, setShowValidationError] = useState(false);
    const { state, dispatch } = useContext(PasswordResetInitContext);
    const { value, valid, error: validationError } = state.get('email');
    const { status: submitStatus, error: submitError } = state.get('submit');

    const setEmail = setEmailFactory(dispatch);
    const submit = submitFactory(dispatch, value);

    const help = showValidationError ? validationError : '';
    const validationState =
        showValidationError && !valid ? InputGroup.VALIDATION_ERROR : null;

    const buttonDisabled = !valid;

    return (
        <div className='entryhall-card-2 entryhall-form-password-reset-init'>
            <div className='entryhall-card-header'>
                <p>
                    {i18n.gettext(
                        "Enter your account email address and we'll send instructions to reset your password"
                    )}
                </p>
                <ApiError error={submitError} />
            </div>
            <div className='entryhall-card-body'>
                <InputGroup
                    i18n={i18n}
                    classNames='entryhall-email-input'
                    label={i18n.gettext('Email Address')}
                    help={help}
                    validationState={validationState}
                    inputProps={{
                        autoFocus: true,
                        autoComplete: 'email',
                        name: 'password-reset-email',
                        value,
                        onChange: setEmail,
                        onBlur: () => setShowValidationError(true),
                        onFocus: () =>
                            showValidationError &&
                            setShowValidationError(false),
                    }}
                />
            </div>
            <div className='entryhall-card-footer'>
                <button
                    type='button'
                    className='btn btn-primary'
                    disabled={buttonDisabled}
                    onClick={
                        submitStatus !== API_STATUSES.IN_PROGRESS
                            ? submit
                            : undefined
                    }
                >
                    {i18n.gettext('Reset Password')}
                    <InlineFeedback status={submitStatus} />
                </button>
            </div>
        </div>
    );
};

export default PasswordResetInitForm;
