import { useReducer } from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import TFAContext, {
    INITIAL_TFA_FORM_STATE,
} from '../../contexts/sign-in/TFAContext';
import TFAForm from '../../forms/sign-in/TFAForm';
import TFAReducer from '../../reducers/sign-in/TFAReducer';

const TFAPage = () => {
    const [state, dispatch] = useReducer(TFAReducer, INITIAL_TFA_FORM_STATE);

    return (
        <TFAContext.Provider value={{ state, dispatch }}>
            <section className='entryhall-page-tfa'>
                <Header />
                <TFAForm />
                <Footer />
            </section>
        </TFAContext.Provider>
    );
};

export default TFAPage;
