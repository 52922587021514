import { API_STATUSES } from '../constants';
import { ErrorId, IApiError } from '../errors';

export interface ISetInputValuePayload {
    value: string | number;
    valid: boolean;
    error: React.ReactNode;
}

export interface ISetFetchInProgressPayload {
    status: API_STATUSES;
}

export interface ISetFetchSuccessPayload {
    status: API_STATUSES;
}

export interface ISetFetchErrorPayload {
    status: API_STATUSES;
    error: IApiError;
}

type Action<T, P> = {
    type: T;
    payload: P;
};

export type ValidationFunc = (
    value: string | number
) => [boolean, React.ReactNode];

// Input actions

export const setInputValue = <T>(
    actionType: T,
    value: string | number,
    validationFunc: ValidationFunc
): Action<T, ISetInputValuePayload> => {
    const [valid, error] = validationFunc(value);

    return {
        type: actionType,
        payload: {
            value,
            valid,
            error,
        },
    };
};

// API actions

export const setFetchInProgress = <T>(
    actionType: T
): Action<T, ISetFetchInProgressPayload> => {
    return {
        type: actionType,
        payload: {
            status: API_STATUSES.IN_PROGRESS,
        },
    };
};

export const setFetchSuccess = <T>(
    actionType: T
): Action<T, ISetFetchSuccessPayload> => {
    return {
        type: actionType,
        payload: {
            status: API_STATUSES.SUCCESS,
        },
    };
};

export const setFetchError = <T>(
    actionType: T,
    error: any
): Action<T, ISetFetchErrorPayload> => {
    const errorIdFromApi = error.parsedBody?.errors?.[0]?.value;
    const errorId = errorIdFromApi in ErrorId ? errorIdFromApi : undefined;
    const errorMessage =
        error.parsedBody?.errors?.[0]?.message || error.message || error;
    const statusCode = error.response?.status;

    return {
        type: actionType,
        payload: {
            error: {
                errorId,
                errorMessage,
                statusCode,
            },
            status: API_STATUSES.ERROR,
        },
    };
};
