import { FormEvent, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import i18n from '@nextroll/ar-i18n';
// @ts-ignore - no types for ar-style-base
import { InputGroup } from '@nextroll/ar-style-base';

import {
    resendSMSFactory,
    setCodeFactory,
    submitFactory,
} from '../../actions/tfa-setup/SmsTFASetupValidationActions';
import InlineFeedback from '../../components/InlineFeedback';
import { ApiError } from '../../components/errors';
import { API_STATUSES } from '../../constants';
import SmsTFASetupValidationContext from '../../contexts/tfa-setup/SmsTFASetupValidationContext';

const SmsTFASetupValidationForm = () => {
    const { state, dispatch } = useContext(SmsTFASetupValidationContext);
    const navigate = useNavigate();
    const code = state.get('code');
    const {
        status: submitStatus,
        error: submitError,
        showError: showSubmitError,
    } = state.get('submit');
    const { status: resendStatus } = state.get('resendSubmit');
    const buttonDisabled = !code.valid;

    const setPhone = setCodeFactory(dispatch);
    const resendSMS = resendSMSFactory(dispatch);
    const submit = submitFactory(dispatch, code.value);
    const onSubmit =
        submitStatus !== API_STATUSES.IN_PROGRESS ? submit : undefined;
    const onFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit && onSubmit();
        return false;
    };

    useEffect(() => {
        if (submitStatus === API_STATUSES.SUCCESS) {
            navigate('../tfa_configure', { state: { success: true } });
        }
    });

    return (
        <div className='entryhall-card-block entryhall-form-sms-tfa-setup'>
            <form id='sms_tfa_setup_form' onSubmit={onFormSubmit}>
                <input type='submit' hidden />
                <div className='entryhall-card-header'>
                    <p className='entryhall-card-header-title'>
                        {i18n.gettext('Enter Two - Step Verification Code')}
                    </p>
                    {!showSubmitError && (
                        <p>
                            {i18n.gettext(
                                'Enter the verification code you received via text message to set up the Two - Step Verification.'
                            )}
                        </p>
                    )}
                    {showSubmitError && <ApiError error={submitError} />}
                </div>
                <div className='entryhall-card-body'>
                    <InputGroup
                        i18n={i18n}
                        classNames='entryhall-tfa-code-input'
                        inputProps={{
                            autoFocus: true,
                            autoComplete: 'off',
                            id: 'sms-tfa-code',
                            placeholder: 123456,
                            value: code.value,
                            onChange: setPhone,
                        }}
                        validationState={
                            showSubmitError ? InputGroup.VALIDATION_ERROR : ''
                        }
                    />
                </div>
                <div className='entryhall-card-footer'>
                    <section className='entryhall-tfa-actions'>
                        <button
                            type='button'
                            id='submit-sms-tfa-setup'
                            className='btn btn-primary'
                            disabled={buttonDisabled}
                            onClick={onSubmit}
                        >
                            {i18n.gettext('Enable Two - Step Verification')}
                            <InlineFeedback status={submitStatus} />
                        </button>
                    </section>
                    <section className='entryhall-tfa-sms-actions'>
                        <span>
                            {i18n.gettext('Verification code not received?')}
                        </span>
                        <button
                            type='button'
                            className='btn btn-link send-sms-action inline-anchor'
                            onClick={
                                resendStatus !== API_STATUSES.IN_PROGRESS
                                    ? resendSMS
                                    : undefined
                            }
                        >
                            {i18n.gettext('Resend SMS')}
                            <InlineFeedback status={resendStatus} />
                        </button>
                    </section>
                </div>
            </form>
        </div>
    );
};

export default SmsTFASetupValidationForm;
