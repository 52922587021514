import {
    ACTION_TYPES,
    SmsTFASetupValidationActions,
} from '../../actions/tfa-setup/SmsTFASetupValidationActions';
import { ImmutableSmsTFASetupValidationState } from '../../contexts/tfa-setup/SmsTFASetupValidationContext';

const SmsTFASetupValidationReducer = (
    state: ImmutableSmsTFASetupValidationState,
    action: SmsTFASetupValidationActions
): ImmutableSmsTFASetupValidationState => {
    switch (action.type) {
        case ACTION_TYPES.SMS_TFA_SETUP_VALIDATION_SET_CODE:
            return state
                .set('code', action.payload)
                .setIn(['submit', 'showError'], false);
        case ACTION_TYPES.SMS_TFA_SETUP_VALIDATION_SUBMIT_IN_PROGRESS:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'error'], null)
                .setIn(['submit', 'showError'], false);
        case ACTION_TYPES.SMS_TFA_SETUP_VALIDATION_SUBMIT_ERROR:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'error'], action.payload.error)
                .setIn(['submit', 'showError'], true);
        case ACTION_TYPES.SMS_TFA_SETUP_VALIDATION_SUBMIT_SUCCESS:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'error'], null)
                .setIn(['submit', 'showError'], false);
        case ACTION_TYPES.SMS_TFA_SETUP_VALIDATION_RESEND_SMS_SUBMIT_IN_PROGRESS:
            return state
                .setIn(['resendSubmit', 'status'], action.payload.status)
                .setIn(['resendSubmit', 'error'], null)
                .setIn(['resendSubmit', 'showError'], false);
        case ACTION_TYPES.SMS_TFA_SETUP_VALIDATION_RESEND_SMS_SUBMIT_ERROR:
            return state
                .setIn(['resendSubmit', 'status'], action.payload.status)
                .setIn(['resendSubmit', 'error'], action.payload.error)
                .setIn(['resendSubmit', 'showError'], true);
        case ACTION_TYPES.SMS_TFA_SETUP_VALIDATION_RESEND_SMS_SUBMIT_SUCCESS:
            return state
                .setIn(['resendSubmit', 'status'], action.payload.status)
                .setIn(['resendSubmit', 'error'], null)
                .setIn(['resendSubmit', 'showError'], false);
    }
};

export default SmsTFASetupValidationReducer;
