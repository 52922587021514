import i18n from '@nextroll/ar-i18n';

const PasswordExpiredConfirmation = () => (
    <div className='entryhall-card-2 entryhall-feedback-password-expired'>
        <div className='entryhall-card-header'>
            <p className='entryhall-card-header-title'>
                <i className='fas ar-icon-success' />
                <span>
                    {i18n.gettext(
                        'Your Password has been successfully changed.'
                    )}
                </span>
            </p>
        </div>
        <div className='entryhall-card-footer'>
            <a
                className='btn btn-primary'
                href='/account/centralredirect'
                rel='noreferrer'
            >
                {i18n.gettext('Continue to Account')}
            </a>
        </div>
    </div>
);

export default PasswordExpiredConfirmation;
