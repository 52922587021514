import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { reportApiError } from '../errors';
import { adrollApi } from '../services/adroll';
import FormSpinner from './FormSpinner';

interface IProps {
    children: React.ReactNode;
}

const StatusWrapper = ({ children }: IProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [redirectSpinner, setRedirectSpinner] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const res = await adrollApi.get('entryhall/status');
                switch (res.type) {
                    case 'internal_redirect':
                        // The internal redirect is prevented if the backend wants to redirect to /profile/signin but the user is on /profile/saml.
                        // This is a hack to prevent users that directly navigate to /profile/saml from being redirected to /profile/signin.
                        // FIXME: on a longer-term senario, we should replace this with a proper support that could be also
                        //    able to remember the last login (sso vs standard).
                        if (
                            `/${res.action}` !== location.pathname &&
                            !(
                                res.action === 'signin' &&
                                location.pathname === '/saml'
                            )
                        ) {
                            navigate(`../${res.action}`);
                        }
                        break;
                    case 'external_redirect':
                        setRedirectSpinner(true);
                        window.location.href = res.action;
                        break;
                    default:
                        throw new Error(
                            `Unknown redirect type "${res.type}" from status API`
                        );
                }
            } catch (error) {
                reportApiError(error);
            }
        })();
    }, []);

    return <>{redirectSpinner ? <FormSpinner /> : children}</>;
};

export default StatusWrapper;
