import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import i18n from '@nextroll/ar-i18n';
// @ts-ignore - no types for ar-style-base
import { Alerts } from '@nextroll/ar-style-base';

import { fetchTFAMethodsFactory } from '../../actions/tfa-setup/TFAMethodsActions';
import {
    API_STATUSES,
    EMAIL_METHOD,
    GAPP_METHOD,
    SMS_METHOD,
} from '../../constants';
import TFAMethodsContext from '../../contexts/tfa-setup/TFAMethodsContext';

const tfaMethods = [
    {
        label: i18n.noop.gettext('Mobile app verification'),
        link: '../setup-mobile-app-tfa',
        key: GAPP_METHOD,
    },
    {
        label: i18n.noop.gettext('Email verification'),
        link: '../setup-email-tfa',
        key: EMAIL_METHOD,
    },
    {
        label: i18n.noop.gettext('Text message verification'),
        link: '../setup-sms-tfa',
        key: SMS_METHOD,
    },
];

const TFAMethodsForm = () => {
    const { state, dispatch } = useContext(TFAMethodsContext);
    const locationState = useLocation().state as
        | { success: boolean }
        | undefined;
    const setupSuccess = locationState?.success;
    const [successVisible, setSuccessVisible] = useState(true);
    const onDismiss = useCallback(
        () => setSuccessVisible(false),
        [successVisible]
    );
    const fetchInit = fetchTFAMethodsFactory(dispatch);

    console.log(state);

    useEffect(() => {
        if (state.get('status') === API_STATUSES.IDLE) {
            fetchInit();
        }
    }, [state.get('status')]);

    const methodEnabled = useCallback(
        (key: string) =>
            state.get('methods') && state.get('methods').includes(key),
        [state.get('methods')]
    );

    const hasMethodsEnabled =
        state.get('methods') && state.get('methods').length > 0;

    return (
        <div className='entryhall-form-tfa-methods'>
            {((setupSuccess && successVisible) ||
                state.get('status') === API_STATUSES.ERROR) && (
                <Alerts
                    i18n={i18n}
                    contextualAlerts={[
                        {
                            title: i18n.gettext(
                                state.get('status') === API_STATUSES.ERROR
                                    ? 'Something went wrong loading the page, please reload it.'
                                    : 'The Verification method has been enabled.'
                            ),
                            timestamp: Date.now(),
                            type:
                                state.get('status') === API_STATUSES.ERROR
                                    ? 'error'
                                    : 'success',
                            alertKey: '0',
                        },
                    ]}
                    onDismissContextualAlert={onDismiss}
                    isStackable={false}
                />
            )}
            <div className='entryhall-card-2'>
                <div className='entryhall-card-header'>
                    <p className='entryhall-card-header-title'>
                        {i18n.gettext('Enable Two-Factor Authentication')}
                    </p>
                    <p>
                        {i18n.gettext(
                            'Two-factor authentication (TFA) is an additional layer of protection to your account to help protect against unauthorized account access.'
                        )}
                    </p>
                    <p>
                        {i18n.gettext(
                            'Please choose and configure at least one verification method.'
                        )}
                    </p>
                </div>
                <div className='entryhall-card-body entryhall-tfa-methods-list'>
                    {tfaMethods.map(
                        ({
                            label,
                            link,
                            key,
                        }: {
                            label: string;
                            link: string;
                            key: string;
                        }) => (
                            <Link
                                key={label}
                                to={link}
                                className={
                                    methodEnabled(key) ||
                                    state.get('status') !== API_STATUSES.SUCCESS
                                        ? 'disabled-link'
                                        : ''
                                }
                            >
                                <button className='ar-card-button'>
                                    {methodEnabled(key) && (
                                        <i className='fas fa-check-circle' />
                                    )}
                                    <span>{i18n.gettext(label)}</span>
                                    <i className='fas fa-arrow-right' />
                                </button>
                            </Link>
                        )
                    )}
                </div>
                {hasMethodsEnabled && (
                    <div className='entryhall-card-footer'>
                        <a
                            className='btn btn-primary'
                            href='/account/centralredirect'
                            rel='noreferrer'
                        >
                            {i18n.gettext('Continue to Account')}
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TFAMethodsForm;
