import { adrollApi } from '../services/adroll';

const ATTEMPTS = 3;

export const fetchCSRF = async () => await adrollApi.get('entryhall/csrf');

export const fetchCSRFWithRetry = async (attempts: number = ATTEMPTS) => {
    /**
     * @argument attempts: the total count of attempts to fetch the CSRF, including the 1st attempt.
     */
    for (let i = 1; i <= attempts; i++) {
        try {
            return await fetchCSRF();
        } catch (error) {
            console.warn(`Unable to pull csrf info, attempt ${i}`);
        }
    }
    console.error('Too many failures trying to pull csrf info');
};
