import { useContext } from 'react';

import i18n from '@nextroll/ar-i18n';

import ThemeWrapperContext from '../../contexts/ThemeWrapperContext';
import PasswordResetInitContext from '../../contexts/password-reset/PasswordResetInitContext';

const PasswordResetInitConfirmation = () => {
    const { state } = useContext(PasswordResetInitContext);
    const { supportEmail } = useContext(ThemeWrapperContext);
    const { value } = state.get('email');

    const expiresAfter = 1;

    return (
        <div className='entryhall-card-1 entryhall-feedback-password-reset-init'>
            <div className='entryhall-card-header'>
                <p className='entryhall-card-header-title'>
                    <i className='fas ar-icon-success' />
                    <span>
                        {i18n.gettext(
                            'Password information sent, check your email'
                        )}
                    </span>
                </p>
            </div>
            <div className='entryhall-card-body'>
                <p>
                    {i18n.gettext(
                        'A message has been sent to <b>{{emailAddress}}</b>. It contains instructions for resetting your password.',
                        {
                            emailAddress: value,
                        }
                    )}{' '}
                    {i18n.ngettext(
                        'For security purposes, this message will expire in <b>{{expiresAfter}} hour</b>.',
                        'For security purposes, this message will expire in <b>{{expiresAfter}} hours</b>.',
                        expiresAfter,
                        { expiresAfter }
                    )}
                </p>
                <p>
                    {i18n.gettext(
                        'Be sure to check your junk email folders or spam filters if you do not see a message appear within a couple of minutes.'
                    )}
                </p>
                <p>
                    {i18n.gettext(
                        'Still not seeing your email? Reach out to <LINK>{{supportEmailAddress}}</LINK><br />or your Account Executive.',
                        {
                            LINK: (
                                <a
                                    href={`mailto:${supportEmail}`}
                                    target='_blank'
                                />
                            ),
                            supportEmailAddress: supportEmail,
                        }
                    )}
                </p>
            </div>
        </div>
    );
};

export default PasswordResetInitConfirmation;
