import { Dispatch } from 'react';

import { API_STATUSES } from '../../constants';
import { reportApiError } from '../../errors';
import { adrollApi } from '../../services/adroll';
import { ActionMap } from '../../typings';
import {
    ISetFetchErrorPayload,
    ISetFetchInProgressPayload,
    ISetInputValuePayload,
    setFetchError,
    setFetchInProgress,
    setInputValue,
} from '../common';

export enum ACTION_TYPES {
    SAML_SIGN_IN_SET_EMAIL = 'SAML_SIGN_IN_SET_EMAIL',
    SAML_SIGN_IN_SET_NEXT = 'SAML_SIGN_IN_SET_NEXT',
    SAML_SIGN_IN_SUBMIT_IN_PROGRESS = 'SAML_SIGN_IN_SUBMIT_IN_PROGRESS',
    SAML_SIGN_IN_SUBMIT_SUCCESS = 'SAML_SIGN_IN_SUBMIT_SUCCESS',
    SAML_SIGN_IN_SUBMIT_ERROR = 'SAML_SIGN_IN_SUBMIT_ERROR',
}

export interface ISetNextPayload {
    value: string;
}

export interface ISetFetchSuccessPayload {
    status: API_STATUSES;
    redirect: string;
    to: string;
}

type SamlSignInPayloads = {
    [ACTION_TYPES.SAML_SIGN_IN_SET_EMAIL]: ISetInputValuePayload;
    [ACTION_TYPES.SAML_SIGN_IN_SET_NEXT]: ISetNextPayload;
    [ACTION_TYPES.SAML_SIGN_IN_SUBMIT_IN_PROGRESS]: ISetFetchInProgressPayload;
    [ACTION_TYPES.SAML_SIGN_IN_SUBMIT_SUCCESS]: ISetFetchSuccessPayload;
    [ACTION_TYPES.SAML_SIGN_IN_SUBMIT_ERROR]: ISetFetchErrorPayload;
};

export type SamlSignInActions =
    ActionMap<SamlSignInPayloads>[keyof ActionMap<SamlSignInPayloads>];

export const setEmailFactory =
    (dispatchFn: Dispatch<SamlSignInActions>) => (event: any) => {
        dispatchFn(
            setInputValue(
                ACTION_TYPES.SAML_SIGN_IN_SET_EMAIL,
                event.target.value,
                () => [true, null]
            )
        );
    };

export const setNextFactory =
    (dispatchFn: Dispatch<SamlSignInActions>) => (next: string) => {
        dispatchFn({
            type: ACTION_TYPES.SAML_SIGN_IN_SET_NEXT,
            payload: {
                value: next,
            },
        });
    };

const setSignInSubmitSuccess = (response: any): SamlSignInActions => {
    return {
        type: ACTION_TYPES.SAML_SIGN_IN_SUBMIT_SUCCESS,
        payload: {
            status: API_STATUSES.SUCCESS,
            redirect: response.type,
            to: response.action,
        },
    };
};

export const submitFactory =
    (dispatchFn: Dispatch<SamlSignInActions>, email: string, next: string) =>
    async () => {
        dispatchFn(
            setFetchInProgress(ACTION_TYPES.SAML_SIGN_IN_SUBMIT_IN_PROGRESS)
        );

        try {
            const res = await adrollApi.post(
                'entryhall/init_saml_signin',
                {},
                {
                    uname: email,
                    next,
                }
            );
            dispatchFn(setSignInSubmitSuccess(res));
        } catch (error) {
            reportApiError(error);
            dispatchFn(
                setFetchError(ACTION_TYPES.SAML_SIGN_IN_SUBMIT_ERROR, error)
            );
        }
    };
