import { Dispatch } from 'react';

import { API_STATUSES } from '../../constants';
import { adrollApi } from '../../services/adroll';
import { ActionMap } from '../../typings';
import {
    ISetFetchErrorPayload,
    ISetFetchInProgressPayload,
    ISetFetchSuccessPayload,
    ISetInputValuePayload,
    setFetchError,
    setFetchInProgress,
    setInputValue,
} from '../common';

export enum ACTION_TYPES {
    TFA_SET_OTP = 'TFA_SET_OTP',
    TFA_FETCH_QR_CODE_IN_PROGRESS = 'TFA_FETCH_QR_CODE_IN_PROGRESS',
    TFA_FETCH_QR_CODE_SUCCESS = 'TFA_FETCH_QR_CODE_SUCCESS',
    TFA_FETCH_QR_CODE_ERROR = 'TFA_FETCH_QR_CODE_ERROR',
    TFA_OTP_SUBMIT_IN_PROGRESS = 'TFA_OTP_SUBMIT_IN_PROGRESS',
    TFA_OTP_SUBMIT_SUCCESS = 'TFA_OTP_SUBMIT_SUCCESS',
    TFA_OTP_SUBMIT_ERROR = 'TFA_OTP_SUBMIT_ERROR',
}

export interface ISetTFAFetchQRCodeSuccessPayload {
    status: API_STATUSES;
    code: string;
}

export interface ISetTFAFetchErrorPayload {
    status: API_STATUSES;
}

type AppTFASetupPayloads = {
    [ACTION_TYPES.TFA_SET_OTP]: ISetInputValuePayload;
    [ACTION_TYPES.TFA_FETCH_QR_CODE_IN_PROGRESS]: ISetFetchInProgressPayload;
    [ACTION_TYPES.TFA_FETCH_QR_CODE_SUCCESS]: ISetTFAFetchQRCodeSuccessPayload;
    [ACTION_TYPES.TFA_FETCH_QR_CODE_ERROR]: ISetTFAFetchErrorPayload;
    [ACTION_TYPES.TFA_OTP_SUBMIT_IN_PROGRESS]: ISetFetchInProgressPayload;
    [ACTION_TYPES.TFA_OTP_SUBMIT_SUCCESS]: ISetFetchSuccessPayload;
    [ACTION_TYPES.TFA_OTP_SUBMIT_ERROR]: ISetFetchErrorPayload;
};

export type AppTFASetupActions =
    ActionMap<AppTFASetupPayloads>[keyof ActionMap<AppTFASetupPayloads>];

const setFetchTFAQRCodeSuccess = (response: any): AppTFASetupActions => {
    return {
        type: ACTION_TYPES.TFA_FETCH_QR_CODE_SUCCESS,
        payload: {
            status: API_STATUSES.SUCCESS,
            code: response.qr_code,
        },
    };
};

const setFetchTFAQRCodeError = (): AppTFASetupActions => {
    return {
        type: ACTION_TYPES.TFA_FETCH_QR_CODE_ERROR,
        payload: {
            status: API_STATUSES.ERROR,
        },
    };
};

const setSubmitOTPSuccess = (): AppTFASetupActions => {
    return {
        type: ACTION_TYPES.TFA_OTP_SUBMIT_SUCCESS,
        payload: {
            status: API_STATUSES.SUCCESS,
        },
    };
};

export const setOTPFactory =
    (dispatchFn: Dispatch<AppTFASetupActions>) => (event: any) => {
        dispatchFn(
            setInputValue(ACTION_TYPES.TFA_SET_OTP, event.target.value, () => {
                const valid =
                    event.target.value.length === 6 &&
                    !isNaN(Number(event.target.value));
                return [valid, null];
            })
        );
    };

export const fetchQRCodeFactory =
    (dispatchFn: Dispatch<AppTFASetupActions>) => async () => {
        dispatchFn(
            setFetchInProgress(ACTION_TYPES.TFA_FETCH_QR_CODE_IN_PROGRESS)
        );
        try {
            const res = await adrollApi.post('entryhall/setup_mobile_app_tfa');
            dispatchFn(setFetchTFAQRCodeSuccess(res));
        } catch (error) {
            dispatchFn(setFetchTFAQRCodeError());
        }
    };

export const submitOTPFactory =
    (dispatchFn: Dispatch<AppTFASetupActions>) => async (otp: string) => {
        dispatchFn(setFetchInProgress(ACTION_TYPES.TFA_OTP_SUBMIT_IN_PROGRESS));
        try {
            await adrollApi.post(
                'entryhall/validate_app_tfa_setup',
                {},
                { token: otp }
            );
            dispatchFn(setSubmitOTPSuccess());
        } catch (error) {
            dispatchFn(setFetchError(ACTION_TYPES.TFA_OTP_SUBMIT_ERROR, error));
        }
    };
