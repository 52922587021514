import { useState } from 'react';

import i18n from '@nextroll/ar-i18n';

import InlineFeedback from '../../components/InlineFeedback';
import { EMAIL_METHOD, GAPP_METHOD, SMS_METHOD } from '../../constants';

interface ITFAHelpProps {
    methods: string[];
    contacts: {
        email: string;
        phone: string;
    };
    sendMessageAction: () => void;
    emailStatus: string;
    smsStatus: string;
}

const TFAHelp = ({
    methods,
    contacts,
    sendMessageAction,
    emailStatus,
    smsStatus,
}: ITFAHelpProps) => {
    // We use resend to decide if we should show the InlineFeedback at the end of the 1st or the 2nd line.
    const [resend, setResend] = useState(false);

    const tfaEmail = methods && methods.includes(EMAIL_METHOD);
    const tfaSMS = methods && methods.includes(SMS_METHOD);
    const tfaGApp = methods && methods.includes(GAPP_METHOD);

    let sendMessageActionMessage = '';
    if (tfaGApp) {
        if (tfaEmail) {
            sendMessageActionMessage = i18n.gettext(
                'Send code via e-mail to {{contact}}',
                {
                    contact: contacts.email,
                }
            );
        } else if (tfaSMS) {
            sendMessageActionMessage = i18n.gettext(
                'Send code via SMS to {{contact}}',
                {
                    contact: contacts.phone,
                }
            );
        }
    } else {
        if (tfaEmail) {
            sendMessageActionMessage = i18n.gettext('Resend e-mail');
        } else if (tfaSMS) {
            sendMessageActionMessage = i18n.gettext('Resend SMS');
        }
    }

    const resendAction = () => {
        setResend(true);
        sendMessageAction();
    };

    return (
        <section className='entryhall-tfa-help'>
            <p className='entryhall-tfa-help-title'>
                {i18n.gettext('Where do I find my code?')}
            </p>
            <ul>
                {tfaGApp && (
                    <li>
                        <span className='entryhall-tfa-authenticator-help'>
                            {i18n.gettext(
                                'Open your Authenticator app to view your code.'
                            )}
                        </span>
                    </li>
                )}
                {(tfaEmail || tfaSMS) && (
                    <section className='entryhall-tfa-sms-actions'>
                        {!tfaGApp && (
                            <li>
                                {i18n.gettext('We sent a code to {{contact}}', {
                                    contact: tfaEmail
                                        ? contacts.email
                                        : contacts.phone,
                                })}
                                {!resend && (
                                    <InlineFeedback
                                        status={
                                            (tfaEmail && emailStatus) ||
                                            (tfaSMS && smsStatus)
                                        }
                                        nonBtnFeedback={true}
                                    />
                                )}
                            </li>
                        )}
                        <li>
                            {!tfaGApp && (
                                <span className='entryhall-tfa-code-not-received'>
                                    {i18n.gettext(
                                        'Verification code not received?'
                                    )}
                                </span>
                            )}
                            <button
                                type='button'
                                className='btn btn-link send-sms-action inline-anchor'
                                onClick={resendAction}
                            >
                                {sendMessageActionMessage}
                                {resend && (
                                    <InlineFeedback
                                        status={
                                            (tfaEmail && emailStatus) ||
                                            (tfaSMS && smsStatus)
                                        }
                                    />
                                )}
                            </button>
                        </li>
                    </section>
                )}
            </ul>
        </section>
    );
};

export default TFAHelp;
