import { useReducer } from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import PasswordExpiredConfirmation from '../../components/password-expired/PasswordExpiredConfirmation';
import { API_STATUSES } from '../../constants';
import PasswordExpiredContext, {
    INITIAL_PASSWORD_EXPIRED_FORM_STATE,
} from '../../contexts/password-expired/PasswordExpiredContext';
import PasswordExpiredForm from '../../forms/password-expired/PasswordExpiredForm';
import PasswordExpiredReducer from '../../reducers/password-expired/PasswordExpiredReducer';

const PasswordExpiredPage = () => {
    const [state, dispatch] = useReducer(
        PasswordExpiredReducer,
        INITIAL_PASSWORD_EXPIRED_FORM_STATE
    );

    const submitSuccess = state.get('submit').status === API_STATUSES.SUCCESS;

    return (
        <PasswordExpiredContext.Provider value={{ state, dispatch }}>
            <section className='entryhall-page-password-expired'>
                <Header />
                {!submitSuccess && <PasswordExpiredForm />}
                {submitSuccess && <PasswordExpiredConfirmation />}
                <Footer />
            </section>
        </PasswordExpiredContext.Provider>
    );
};

export default PasswordExpiredPage;
