import { Map } from 'immutable';
import { createContext } from 'react';

import { TFAMethodsActions } from '../../actions/tfa-setup/TFAMethodsActions';
import { API_STATUSES } from '../../constants';
import { ImmutableMap } from '../../typings';

export interface ITFAMethodsState {
    status: string;
    methods: string[];
}

export type ImmutableTFAMethodsSetupState = ImmutableMap<ITFAMethodsState>;

interface ITFAMethodsContextValue {
    state: ImmutableTFAMethodsSetupState;
    dispatch: React.Dispatch<TFAMethodsActions>;
}

const initialState: ITFAMethodsState = {
    status: API_STATUSES.IDLE,
    methods: [],
};

export const INITIAL_TFA_METHODS_STATE = Map(initialState);

const defaultValue: ITFAMethodsContextValue = {
    state: INITIAL_TFA_METHODS_STATE,
    dispatch: () => null,
};

const TFAMethodsContext = createContext<ITFAMethodsContextValue>(defaultValue);

export default TFAMethodsContext;
