import {
    ACTION_TYPES,
    PasswordResetInitActions,
} from '../../actions/password-reset/PasswordResetInitActions';
import { ImmutablePasswordResetInitState } from '../../contexts/password-reset/PasswordResetInitContext';

const PasswordResetInitReducer = (
    state: ImmutablePasswordResetInitState,
    action: PasswordResetInitActions
): ImmutablePasswordResetInitState => {
    switch (action.type) {
        case ACTION_TYPES.PASSWORD_RESET_INIT_SET_EMAIL:
            return state.set('email', action.payload);

        case ACTION_TYPES.PASSWORD_RESET_INIT_SUBMIT_IN_PROGRESS:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'error'], null);

        case ACTION_TYPES.PASSWORD_RESET_INIT_SUBMIT_ERROR:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'error'], action.payload.error);

        case ACTION_TYPES.PASSWORD_RESET_INIT_SUBMIT_SUCCESS:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'error'], null);
    }
};

export default PasswordResetInitReducer;
