import { adrollApi } from '../../services/adroll';
import { ActionMap } from '../../typings';
import {
    ISetFetchErrorPayload,
    ISetFetchInProgressPayload,
    ISetFetchSuccessPayload,
    ISetInputValuePayload,
    setFetchError,
    setFetchInProgress,
    setFetchSuccess,
    setInputValue,
} from '../common';

export enum ACTION_TYPES {
    PASSWORD_EXPIRED_SET_CURRENT_PASSWORD = 'PASSWORD_EXPIRED_SET_CURRENT_PASSWORD',
    PASSWORD_EXPIRED_SET_PASSWORD = 'PASSWORD_EXPIRED_SET_PASSWORD',
    PASSWORD_EXPIRED_SET_PASSWORD_CONFIRMATION = 'PASSWORD_EXPIRED_SET_PASSWORD_CONFIRMATION',
    PASSWORD_EXPIRED_SUBMIT_IN_PROGRESS = 'PASSWORD_EXPIRED_SUBMIT_IN_PROGRESS',
    PASSWORD_EXPIRED_SUBMIT_SUCCESS = 'PASSWORD_EXPIRED_SUBMIT_SUCCESS',
    PASSWORD_EXPIRED_SUBMIT_ERROR = 'PASSWORD_EXPIRED_SUBMIT_ERROR',
}

type PasswordExpiredPayloads = {
    [ACTION_TYPES.PASSWORD_EXPIRED_SET_CURRENT_PASSWORD]: ISetInputValuePayload;
    [ACTION_TYPES.PASSWORD_EXPIRED_SET_PASSWORD]: ISetInputValuePayload;
    [ACTION_TYPES.PASSWORD_EXPIRED_SET_PASSWORD_CONFIRMATION]: ISetInputValuePayload;
    [ACTION_TYPES.PASSWORD_EXPIRED_SUBMIT_IN_PROGRESS]: ISetFetchInProgressPayload;
    [ACTION_TYPES.PASSWORD_EXPIRED_SUBMIT_SUCCESS]: ISetFetchSuccessPayload;
    [ACTION_TYPES.PASSWORD_EXPIRED_SUBMIT_ERROR]: ISetFetchErrorPayload;
};

export type PasswordExpiredActions =
    ActionMap<PasswordExpiredPayloads>[keyof ActionMap<PasswordExpiredPayloads>];

export const setCurrentPasswordFactory =
    (dispatchFn: React.Dispatch<PasswordExpiredActions>) =>
    (event: any, valid: boolean, errors: string[]) => {
        // Override PasswordInput validation to allow old and weak passwords to be modified.
        // Hence, code just ensures the field is not empty.
        dispatchFn(
            setInputValue(
                ACTION_TYPES.PASSWORD_EXPIRED_SET_CURRENT_PASSWORD,
                event.target.value,
                () => [event.target.value !== '', []]
            )
        );
    };

export const setPasswordFactory =
    (dispatchFn: React.Dispatch<PasswordExpiredActions>) =>
    (event: any, valid: boolean, errors: string[]) => {
        dispatchFn(
            setInputValue(
                ACTION_TYPES.PASSWORD_EXPIRED_SET_PASSWORD,
                event.target.value,
                () => [valid, errors]
            )
        );
    };

export const setPasswordConfirmationFactory =
    (dispatchFn: React.Dispatch<PasswordExpiredActions>) =>
    (event: any, valid: boolean, errors: string[]) => {
        dispatchFn(
            setInputValue(
                ACTION_TYPES.PASSWORD_EXPIRED_SET_PASSWORD_CONFIRMATION,
                event.target.value,
                () => [valid, errors]
            )
        );
    };

export const submitFactory =
    (
        dispatchFn: React.Dispatch<PasswordExpiredActions>,
        currentPassword: string,
        password: string,
        passwordConfirmation: string
    ) =>
    async () => {
        dispatchFn(
            setFetchInProgress(ACTION_TYPES.PASSWORD_EXPIRED_SUBMIT_IN_PROGRESS)
        );
        try {
            await adrollApi.post(
                'entryhall/renew_password',
                {},
                {
                    current_password: currentPassword,
                    new_password: password,
                    password_confirmation: passwordConfirmation,
                }
            );
            dispatchFn(
                setFetchSuccess(ACTION_TYPES.PASSWORD_EXPIRED_SUBMIT_SUCCESS)
            );
        } catch (error) {
            dispatchFn(
                setFetchError(ACTION_TYPES.PASSWORD_EXPIRED_SUBMIT_ERROR, error)
            );
        }
    };
