import { useReducer } from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import SmsTFASetupValidationContext, {
    INITIAL_SMS_TFA_SETUP_VALIDATION_FORM_STATE,
} from '../../contexts/tfa-setup/SmsTFASetupValidationContext';
import SmsTFASetupValidationForm from '../../forms/tfa-setup/SmsTFASetupValidationForm';
import SmsTFASetupValidationReducer from '../../reducers/tfa-setup/SmsTFASetupValidationReducer';

const SmsTFASetupValidationPage = () => {
    const [state, dispatch] = useReducer(
        SmsTFASetupValidationReducer,
        INITIAL_SMS_TFA_SETUP_VALIDATION_FORM_STATE
    );

    return (
        <SmsTFASetupValidationContext.Provider value={{ state, dispatch }}>
            <section className='entryhall-page-sms-tfa-setup'>
                <Header />
                <SmsTFASetupValidationForm />
                <Footer />
            </section>
        </SmsTFASetupValidationContext.Provider>
    );
};

export default SmsTFASetupValidationPage;
