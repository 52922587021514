import { useContext } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import i18n from '@nextroll/ar-i18n';
// @ts-ignore - no types for ar-style-base
import { BUSINESS_UNIT_ROLLWORKS } from '@nextroll/ar-style-base';

// @ts-ignore - no types for an image.
import ArHeaderImage from '../../assets/error_page_header.png';
// @ts-ignore - no types for an image.
import RwHeaderImage from '../../assets/error_page_header_rw.png';
import ThemeWrapperContext from '../../contexts/ThemeWrapperContext';

const ErrorPage = () => {
    const { code } = useParams();
    const { businessUnit } = useContext(ThemeWrapperContext);
    const searchParams = useSearchParams()[0];
    const advertisableParam = searchParams.get('advertisable');

    let title: string;
    let message: React.ReactNode;
    switch (code) {
        case '401':
        case '403':
            title = i18n.gettext('Access Forbidden');
            const isDemoAdvertisable =
                advertisableParam &&
                advertisableParam.startsWith('DEMOADVERTISABLE');

            if (isDemoAdvertisable) {
                message = i18n.gettext(
                    'The demo account associated with this link is old and is no longer available anymore. Please use the currently available demo account instead'
                );
            } else {
                message = i18n.gettext(
                    "Sorry, you don't have access to this page."
                );
            }
            break;
        case '404':
            title = i18n.gettext('Page Not Found');
            message = (
                <>
                    <p className='lost'>{i18n.gettext('This page is lost.')}</p>
                    <p className='let-it-go'>
                        {i18n.gettext("It's best to just let it go.")}
                    </p>
                </>
            );
            break;
        case '405':
            title = i18n.gettext('Method Not Allowed');
            message = i18n.gettext(
                'Sorry, that method is not allowed for this page'
            );
            break;
        case '410':
            title = i18n.gettext('Gone');
            message = i18n.gettext('This profile has been deleted.');
            break;
        default:
            title = i18n.gettext('Oops');
            message = (
                <>
                    <p>
                        {i18n.gettext(
                            'There has been a problem with your request. We apologize for the inconvenience. Our engineering team has been notified. Rest assured that your data is safe.'
                        )}
                    </p>
                    <p>
                        {i18n.gettext(
                            'If you have arrived at this page while attempting to process a transaction, your card has not been charged.'
                        )}
                    </p>
                </>
            );
    }

    const headerImage =
        businessUnit === BUSINESS_UNIT_ROLLWORKS
            ? RwHeaderImage
            : ArHeaderImage;
    const headerImageSrc = `${process.env.STATIC_PATH}${headerImage}`;
    const hcHref =
        businessUnit === BUSINESS_UNIT_ROLLWORKS
            ? 'https://help.rollworks.com/hc/en-us'
            : 'https://help.adroll.com/hc/en-us';

    return (
        <section className='entryhall-page-error'>
            <img src={headerImageSrc} className='entryhall-page-error-image' />
            <section className='entryhall-page-error-main'>
                <div className='entryhall-page-error-card-block'>
                    <div className='entryhall-card-header'>
                        <p className='entryhall-card-header-title'>{title}</p>
                    </div>
                    <div className='entryhall-card-body'>{message}</div>
                </div>
                <div className='entryhall-page-error-links'>
                    <a href='/'>{i18n.gettext('Home')}</a>
                    <a href={hcHref}>{i18n.gettext('Help Center')}</a>
                </div>
            </section>
        </section>
    );
};

export default ErrorPage;
