import { useEffect, useReducer } from 'react';
import { useSearchParams } from 'react-router-dom';

import { setParams } from '../../actions/password-reset/PasswordResetSaveActions';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import PasswordResetSaveConfirmation from '../../components/password-reset/PasswordResetSaveConfirmation';
import PasswordResetSaveInvalid from '../../components/password-reset/PasswordResetSaveInvalid';
import { API_STATUSES } from '../../constants';
import PasswordResetSaveContext, {
    INITIAL_PASSWORD_RESET_SAVE_FORM_STATE,
} from '../../contexts/password-reset/PasswordResetSaveContext';
import PasswordResetSaveForm from '../../forms/password-reset/PasswordResetSaveForm';
import PasswordResetSaveReducer from '../../reducers/password-reset/PasswordResetSaveReducer';

const PasswordResetSavePage = () => {
    const [state, dispatch] = useReducer(
        PasswordResetSaveReducer,
        INITIAL_PASSWORD_RESET_SAVE_FORM_STATE
    );

    const { token, email, valid } = Object.fromEntries(useSearchParams()[0]);
    useEffect(() => {
        setParams(dispatch, token, email, valid);
    }, [token, email, valid]);

    const submitSuccess = state.get('submit').status === API_STATUSES.SUCCESS;
    const backendAccepted = valid === 'True';

    return (
        <PasswordResetSaveContext.Provider value={{ state, dispatch }}>
            <section className='entryhall-page-password-reset-save'>
                <Header />
                {!backendAccepted && <PasswordResetSaveInvalid />}
                {backendAccepted && !submitSuccess && <PasswordResetSaveForm />}
                {submitSuccess && <PasswordResetSaveConfirmation />}
                <Footer />
            </section>
        </PasswordResetSaveContext.Provider>
    );
};

export default PasswordResetSavePage;
