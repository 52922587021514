import { useReducer } from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import EmailTFASetupContext, {
    INITIAL_EMAIL_TFA_SETUP_STATE,
} from '../../contexts/tfa-setup/EmailTFASetupContext';
import EmailTFASetupForm from '../../forms/tfa-setup/EmailTFASetupForm';
import EmailTFASetupReducer from '../../reducers/tfa-setup/EmailTFASetupReducer';

const EmailTFASetupPage = () => {
    const [state, dispatch] = useReducer(
        EmailTFASetupReducer,
        INITIAL_EMAIL_TFA_SETUP_STATE
    );

    return (
        <EmailTFASetupContext.Provider value={{ state, dispatch }}>
            <section className='entryhall-page-email-tfa-setup'>
                <Header />
                <EmailTFASetupForm />
                <Footer />
            </section>
        </EmailTFASetupContext.Provider>
    );
};

export default EmailTFASetupPage;
