import { Dispatch } from 'react';

import { adrollApi } from '../../services/adroll';
import { ActionMap } from '../../typings';
import {
    ISetFetchErrorPayload,
    ISetFetchInProgressPayload,
    ISetFetchSuccessPayload,
    setFetchError,
    setFetchInProgress,
    setFetchSuccess,
} from '../common';

export enum ACTION_TYPES {
    EMAIL_TFA_SETUP_SUBMIT_IN_PROGRESS = 'EMAIL_TFA_SETUP_SUBMIT_IN_PROGRESS',
    EMAIL_TFA_SETUP_SUBMIT_SUCCESS = 'EMAIL_TFA_SETUP_SUBMIT_SUCCESS',
    EMAIL_TFA_SETUP_SUBMIT_ERROR = 'EMAIL_TFA_SETUP_SUBMIT_ERROR',
}

type EmailTFASetupPayloads = {
    [ACTION_TYPES.EMAIL_TFA_SETUP_SUBMIT_IN_PROGRESS]: ISetFetchInProgressPayload;
    [ACTION_TYPES.EMAIL_TFA_SETUP_SUBMIT_SUCCESS]: ISetFetchSuccessPayload;
    [ACTION_TYPES.EMAIL_TFA_SETUP_SUBMIT_ERROR]: ISetFetchErrorPayload;
};

export type EmailTFASetupActions =
    ActionMap<EmailTFASetupPayloads>[keyof ActionMap<EmailTFASetupPayloads>];

export const submitEmailFactory =
    (dispatchFn: Dispatch<EmailTFASetupActions>) => async () => {
        dispatchFn(
            setFetchInProgress(ACTION_TYPES.EMAIL_TFA_SETUP_SUBMIT_IN_PROGRESS)
        );
        try {
            await adrollApi.post('entryhall/enable_email_tfa', {}, {});
            dispatchFn(
                setFetchSuccess(ACTION_TYPES.EMAIL_TFA_SETUP_SUBMIT_SUCCESS)
            );
        } catch (error) {
            dispatchFn(
                setFetchError(ACTION_TYPES.EMAIL_TFA_SETUP_SUBMIT_ERROR, error)
            );
        }
    };
