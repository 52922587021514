import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import i18n from '@nextroll/ar-i18n';

import { submitEmailFactory } from '../../actions/tfa-setup/EmailTFASetupActions';
import InlineFeedback from '../../components/InlineFeedback';
import { ApiError } from '../../components/errors';
import { API_STATUSES } from '../../constants';
import EmailTFASetupContext from '../../contexts/tfa-setup/EmailTFASetupContext';

const EmailTFASetupForm = () => {
    const { state, dispatch } = useContext(EmailTFASetupContext);
    const navigate = useNavigate();
    const {
        status: submitStatus,
        error: submitError,
        showError: showSubmitError,
    } = state.get('submit');

    const submit = submitEmailFactory(dispatch);
    const onSubmit =
        submitStatus !== API_STATUSES.IN_PROGRESS ? submit : undefined;

    const onBack = () => {
        navigate('../tfa_configure');
    };

    useEffect(() => {
        if (submitStatus === API_STATUSES.SUCCESS) {
            navigate('../tfa_configure', { state: { success: true } });
        }
    }, [submitStatus]);

    return (
        <div className='entryhall-card-block entryhall-form-email-tfa-setup'>
            <div className='entryhall-card-header'>
                <p className='entryhall-card-header-title'>
                    {i18n.gettext('Set Up Email for Two-Factor Authentication')}
                </p>
                {!showSubmitError && (
                    <>
                        <p>
                            {i18n.gettext(
                                'We support sending Two-Factor Authentication codes to your email address.'
                            )}
                        </p>
                    </>
                )}
                {showSubmitError && <ApiError error={submitError} />}
            </div>
            <div className='entryhall-card-footer'>
                <section className='entryhall-tfa-actions'>
                    <button
                        type='button'
                        id='submit-email-tfa-setup'
                        className='btn btn-primary'
                        onClick={onSubmit}
                    >
                        {i18n.gettext('Activate Email as TFA')}
                        <InlineFeedback status={submitStatus} />
                    </button>
                    <button
                        type='button'
                        className='btn btn-default'
                        onClick={onBack}
                    >
                        {i18n.gettext('Cancel')}
                    </button>
                </section>
            </div>
        </div>
    );
};

export default EmailTFASetupForm;
