import { FormEvent, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import i18n from '@nextroll/ar-i18n';
// @ts-ignore - no types for ar-style-base
import { InputGroup } from '@nextroll/ar-style-base';

import {
    fetchQRCodeFactory,
    setOTPFactory,
    submitOTPFactory,
} from '../../actions/tfa-setup/AppTFASetupActions';
import InlineFeedback from '../../components/InlineFeedback';
import { ApiError } from '../../components/errors';
import { API_STATUSES } from '../../constants';
import AppTFASetupContext from '../../contexts/tfa-setup/AppTFASetupContex';

const AppTFASetupForm = () => {
    const { state, dispatch } = useContext(AppTFASetupContext);
    const navigate = useNavigate();

    const otp = state.get('otp');
    const qr = state.get('qr_code');
    const submission = state.get('submission');

    const buttonDisabled = !otp.valid;

    const setOTP = setOTPFactory(dispatch);
    const fetchQRCode = fetchQRCodeFactory(dispatch);
    const submit = submitOTPFactory(dispatch);

    const onSubmit = () => {
        if (submission.status !== API_STATUSES.IN_PROGRESS) {
            submit(otp.value);
        }
    };
    const onFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit && onSubmit();
        return false;
    };
    const onBack = () => {
        navigate('../tfa_configure');
    };

    useEffect(() => {
        fetchQRCode();
    }, []);
    useEffect(() => {
        if (submission.status === API_STATUSES.SUCCESS) {
            navigate('../tfa_configure', { state: { success: true } });
        }
    }, [submission.status]);

    return (
        <div className='entryhall-card-block entryhall-form-app-tfa-setup'>
            <form id='app_tfa_setup_form' onSubmit={onFormSubmit}>
                <div className='entryhall-card-header'>
                    <p className='entryhall-card-header-title'>
                        {i18n.gettext(
                            'Set Up an Authenticator App for Two-Factor Authentication'
                        )}
                    </p>
                    {!submission.showError && (
                        <p>
                            {i18n.gettext(
                                'You can use an authenticator app for Two-Factor Authentication when you sign in. You will be prompted to enter a unique code from this app. Follow the steps below to configure the authenticator app.'
                            )}
                        </p>
                    )}
                    {submission.showError && (
                        <ApiError error={submission.error} />
                    )}
                </div>

                <div className='entryhall-card-body'>
                    <ol>
                        <li>
                            {i18n.gettext(
                                'Download and install a mobile app authenticator on your phone, such as' +
                                    ' <GLink>Google Authenticator</GLink> or <ALink>Authy</ALink>.',
                                {
                                    GLink: (
                                        <a
                                            href='https://support.google.com/accounts/answer/1066447'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        />
                                    ),
                                    ALink: (
                                        <a
                                            href='https://play.google.com/store/apps/details?id=com.authy.authy'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        />
                                    ),
                                }
                            )}
                        </li>
                        <li>
                            {i18n.gettext(
                                '<Bold>Set up a new account</Bold> within the app.',
                                {
                                    Bold: <b />,
                                }
                            )}
                        </li>
                        <li>
                            {i18n.gettext(
                                '<Bold>Scan the barcode</Bold> below.',
                                {
                                    Bold: <b />,
                                }
                            )}
                            <div className='thumbnail'>
                                {qr.status === API_STATUSES.SUCCESS &&
                                    qr.code && (
                                        <img
                                            src={`data:image/png;base64,${qr.code}`}
                                        />
                                    )}
                                {qr.status !== API_STATUSES.SUCCESS && (
                                    <div>
                                        <button
                                            type='button'
                                            className='btn btn-default'
                                            onClick={fetchQRCode}
                                        >
                                            {i18n.gettext('Load')}
                                            <InlineFeedback
                                                status={qr.status}
                                            />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </li>
                        <li>
                            {i18n.gettext(
                                '<Bold>Enter the verification code</Bold> that displays in the app.',
                                {
                                    Bold: <b />,
                                }
                            )}

                            <InputGroup
                                i18n={i18n}
                                classNames='entryhall-tfa-token-input'
                                inputProps={{
                                    autoComplete: 'off',
                                    autoFocus: true,
                                    type: 'text',
                                    value: otp.value,
                                    onChange: setOTP,
                                }}
                            />
                        </li>
                    </ol>
                </div>

                <div className='entryhall-card-footer'>
                    <section className='entryhall-card-actions'>
                        <button
                            type='button'
                            className='btn btn-primary'
                            disabled={buttonDisabled}
                            onClick={onSubmit}
                        >
                            {i18n.gettext('Confirm')}
                            <InlineFeedback status={submission.status} />
                        </button>
                        <button
                            type='button'
                            className='btn btn-default'
                            onClick={onBack}
                        >
                            {i18n.gettext('Cancel')}
                        </button>
                    </section>
                </div>
            </form>
        </div>
    );
};

export default AppTFASetupForm;
