import {
    ACTION_TYPES,
    AppTFASetupActions,
} from '../../actions/tfa-setup/AppTFASetupActions';
import { ImmutableAppTFASetupState } from '../../contexts/tfa-setup/AppTFASetupContex';

const AppTFASetupReducer = (
    state: ImmutableAppTFASetupState,
    action: AppTFASetupActions
): ImmutableAppTFASetupState => {
    switch (action.type) {
        case ACTION_TYPES.TFA_SET_OTP:
            return state
                .set('otp', action.payload)
                .setIn(['submit', 'showError'], false);
        case ACTION_TYPES.TFA_FETCH_QR_CODE_IN_PROGRESS:
            return state
                .setIn(['qr_code', 'status'], action.payload.status)
                .setIn(['qr_code', 'code'], '');
        case ACTION_TYPES.TFA_FETCH_QR_CODE_SUCCESS:
            return state
                .setIn(['qr_code', 'status'], action.payload.status)
                .setIn(['qr_code', 'code'], action.payload.code);
        case ACTION_TYPES.TFA_FETCH_QR_CODE_ERROR:
            return state
                .setIn(['qr_code', 'status'], action.payload.status)
                .setIn(['qr_code', 'code'], '');
        case ACTION_TYPES.TFA_OTP_SUBMIT_IN_PROGRESS:
            return state
                .setIn(['submission', 'status'], action.payload.status)
                .setIn(['submission', 'error'], null)
                .setIn(['submission', 'showError'], false);
        case ACTION_TYPES.TFA_OTP_SUBMIT_SUCCESS:
            return state
                .setIn(['submission', 'status'], action.payload.status)
                .setIn(['submission', 'error'], null)
                .setIn(['submission', 'showError'], false);
        case ACTION_TYPES.TFA_OTP_SUBMIT_ERROR:
            return state
                .setIn(['submission', 'status'], action.payload.status)
                .setIn(['submission', 'error'], action.payload.error)
                .setIn(['submission', 'showError'], true);
    }
};

export default AppTFASetupReducer;
