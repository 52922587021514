import { Map } from 'immutable';
import React, { createContext } from 'react';

import { PasswordExpiredActions } from '../../actions/password-expired/PasswordExpiredActions';
import { API_STATUSES } from '../../constants';
import { IApiError } from '../../errors';
import { ImmutableMap } from '../../typings';

interface IPasswordState {
    value: string;
    valid: boolean;
    error: string;
}

export interface IPasswordExpiredState {
    currentPassword: IPasswordState;
    password: IPasswordState;
    passwordConfirmation: IPasswordState;
    submit: {
        status: string;
        error: IApiError;
        showError: boolean;
    };
}

export type ImmutablePasswordExpiredState = ImmutableMap<IPasswordExpiredState>;

interface IPasswordExpiredContextValue {
    state: ImmutablePasswordExpiredState;
    dispatch: React.Dispatch<PasswordExpiredActions>;
}

const initialState: IPasswordExpiredState = {
    currentPassword: {
        value: '',
        valid: false,
        error: null,
    },
    password: {
        value: '',
        valid: false,
        error: null,
    },
    passwordConfirmation: {
        value: '',
        valid: false,
        error: null,
    },
    submit: {
        status: API_STATUSES.IDLE,
        error: null,
        showError: false,
    },
};

export const INITIAL_PASSWORD_EXPIRED_FORM_STATE = Map(initialState);

const defaultValue: IPasswordExpiredContextValue = {
    state: INITIAL_PASSWORD_EXPIRED_FORM_STATE,
    dispatch: () => null,
};

const PasswordExpiredContext =
    createContext<IPasswordExpiredContextValue>(defaultValue);

export default PasswordExpiredContext;
