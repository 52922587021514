import i18n from '@nextroll/ar-i18n';

import { ERROR_MESSAGES, ErrorId, IApiError } from '../errors';

interface IApiErrorProps {
    error: IApiError;
    supportEmailAddress?: string;
}

export const ApiError = ({ error, supportEmailAddress }: IApiErrorProps) => {
    if (!error) {
        return null;
    }

    let errorMessage = i18n.gettext(
        'There has been an error with your request. Please try again later.'
    );
    if (error.errorId && ERROR_MESSAGES[ErrorId[error.errorId]]) {
        errorMessage = i18n.gettext(ERROR_MESSAGES[ErrorId[error.errorId]]);
    } else if (error.statusCode === 406) {
        errorMessage = i18n.gettext(
            'There has been an error with your request. Reach out to <LINK>{{supportEmailAddress}}</LINK> or your Account Executive.',
            {
                LINK: <a href={`mailto:${supportEmailAddress}`} />,
                supportEmailAddress,
            }
        );
    }

    return <p className='entryhall-error-text'>{errorMessage}</p>;
};

export const EmailValidationError = () => (
    <span>
        {i18n.gettext('Please enter a valid email address.')}
        <br />
        <ul>
            <li>{i18n.gettext('Include an "@" in the email address')}</li>
            <li>{i18n.gettext('Make sure there are no spaces or commas')}</li>
        </ul>
    </span>
);
