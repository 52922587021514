import i18n from '@nextroll/ar-i18n';

const PasswordResetSaveConfirmation = () => (
    <div className='entryhall-card-2 entryhall-feedback-password-reset-save'>
        <div className='entryhall-card-header'>
            <p className='entryhall-card-header-title'>
                <i className='fas ar-icon-success' />
                <span>
                    {i18n.gettext(
                        'Your Password has been successfully changed.'
                    )}
                </span>
            </p>
        </div>
        <div className='entryhall-card-body'>
            <p>
                {i18n.gettext(
                    'You have been automatically signed in using your new password.'
                )}
            </p>
        </div>
        <div className='entryhall-card-footer'>
            <a
                className='btn btn-primary'
                href='/account/centralredirect'
                rel='noreferrer'
            >
                {i18n.gettext('Continue to Account')}
            </a>
        </div>
    </div>
);

export default PasswordResetSaveConfirmation;
