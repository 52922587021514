import { Map } from 'immutable';
import { createContext } from 'react';

import { ISetInputValuePayload } from '../../actions/common';
import { SignInActions } from '../../actions/sign-in/SignInActions';
import { API_STATUSES } from '../../constants';
import { IApiError } from '../../errors';
import { ImmutableMap } from '../../typings';

interface IEmailState extends ISetInputValuePayload {
    value: string;
}

interface IPasswordState extends ISetInputValuePayload {
    value: string;
}

export interface ISignInState {
    email: IEmailState;
    password: IPasswordState;
    next: string;
    submit: {
        status: string;
        error: IApiError;
        showError: boolean;
        redirect: string;
        to: string;
    };
}

export type ImmutableSignInState = ImmutableMap<ISignInState>;

interface ISignInContextValue {
    state: ImmutableSignInState;
    dispatch: React.Dispatch<SignInActions>;
}

const initialState: ISignInState = {
    email: {
        value: '',
        valid: true,
        error: null,
    },
    password: {
        value: '',
        valid: true,
        error: null,
    },
    next: '',
    submit: {
        status: API_STATUSES.IDLE,
        error: null,
        showError: false,
        redirect: null,
        to: null,
    },
};

export const INITIAL_SIGN_IN_FORM_STATE = Map(initialState);

const defaultValue: ISignInContextValue = {
    state: INITIAL_SIGN_IN_FORM_STATE,
    dispatch: () => null,
};

const SignInContext = createContext<ISignInContextValue>(defaultValue);

export default SignInContext;
