import { EmailValidationError } from '../../components/errors';
import { adrollApi } from '../../services/adroll';
import { ActionMap } from '../../typings';
import { isEmailValid } from '../../utils/validators';
import {
    ISetFetchErrorPayload,
    ISetFetchInProgressPayload,
    ISetFetchSuccessPayload,
    ISetInputValuePayload,
    setFetchError,
    setFetchInProgress,
    setFetchSuccess,
    setInputValue,
} from '../common';

export enum ACTION_TYPES {
    PASSWORD_RESET_INIT_SET_EMAIL = 'PASSWORD_RESET_INIT_SET_EMAIL',
    PASSWORD_RESET_INIT_SUBMIT_IN_PROGRESS = 'PASSWORD_RESET_INIT_SUBMIT_IN_PROGRESS',
    PASSWORD_RESET_INIT_SUBMIT_SUCCESS = 'PASSWORD_RESET_INIT_SUBMIT_SUCCESS',
    PASSWORD_RESET_INIT_SUBMIT_ERROR = 'PASSWORD_RESET_INIT_SUBMIT_ERROR',
}

type PasswordResetInitPayloads = {
    [ACTION_TYPES.PASSWORD_RESET_INIT_SET_EMAIL]: ISetInputValuePayload;
    [ACTION_TYPES.PASSWORD_RESET_INIT_SUBMIT_IN_PROGRESS]: ISetFetchInProgressPayload;
    [ACTION_TYPES.PASSWORD_RESET_INIT_SUBMIT_SUCCESS]: ISetFetchSuccessPayload;
    [ACTION_TYPES.PASSWORD_RESET_INIT_SUBMIT_ERROR]: ISetFetchErrorPayload;
};

export type PasswordResetInitActions =
    ActionMap<PasswordResetInitPayloads>[keyof ActionMap<PasswordResetInitPayloads>];

export const setEmailFactory =
    (dispatchFn: React.Dispatch<PasswordResetInitActions>) => (event: any) => {
        dispatchFn(
            setInputValue(
                ACTION_TYPES.PASSWORD_RESET_INIT_SET_EMAIL,
                event.target.value,
                (v: string | number) => {
                    const valid = isEmailValid(v as string);
                    return [valid, !valid && <EmailValidationError />];
                }
            )
        );
    };

export const submitFactory =
    (dispatchFn: React.Dispatch<PasswordResetInitActions>, email: string) =>
    async () => {
        dispatchFn(
            setFetchInProgress(
                ACTION_TYPES.PASSWORD_RESET_INIT_SUBMIT_IN_PROGRESS
            )
        );
        try {
            await adrollApi.post(
                'entryhall/init_password_reset',
                {},
                { email }
            );
            dispatchFn(
                setFetchSuccess(ACTION_TYPES.PASSWORD_RESET_INIT_SUBMIT_SUCCESS)
            );
        } catch (error) {
            dispatchFn(
                setFetchError(
                    ACTION_TYPES.PASSWORD_RESET_INIT_SUBMIT_ERROR,
                    error
                )
            );
        }
    };
