import { Map } from 'immutable';
import { createContext } from 'react';

import { ISetInputValuePayload } from '../../actions/common';
import { SmsTFASetupValidationActions } from '../../actions/tfa-setup/SmsTFASetupValidationActions';
import { API_STATUSES } from '../../constants';
import { IApiError } from '../../errors';
import { ImmutableMap } from '../../typings';

interface ICodeState extends ISetInputValuePayload {
    value: string;
}

export interface ISmsTFASetupValidationState {
    code: ICodeState;
    resendSubmit: {
        status: string;
        error: IApiError;
        showError: boolean;
    };
    submit: {
        status: string;
        error: IApiError;
        showError: boolean;
    };
}

export type ImmutableSmsTFASetupValidationState =
    ImmutableMap<ISmsTFASetupValidationState>;

interface ISmsTFASetupValidationContextValue {
    state: ImmutableSmsTFASetupValidationState;
    dispatch: React.Dispatch<SmsTFASetupValidationActions>;
}

const initialState: ISmsTFASetupValidationState = {
    code: {
        value: '',
        valid: true,
        error: null,
    },
    resendSubmit: {
        status: API_STATUSES.IDLE,
        error: null,
        showError: false,
    },
    submit: {
        status: API_STATUSES.IDLE,
        error: null,
        showError: false,
    },
};

export const INITIAL_SMS_TFA_SETUP_VALIDATION_FORM_STATE = Map(initialState);

const defaultValue: ISmsTFASetupValidationContextValue = {
    state: INITIAL_SMS_TFA_SETUP_VALIDATION_FORM_STATE,
    dispatch: () => null,
};

const SmsTFASetupValidationContext =
    createContext<ISmsTFASetupValidationContextValue>(defaultValue);

export default SmsTFASetupValidationContext;
