import { Map } from 'immutable';
import React, { createContext } from 'react';

import { PasswordResetSaveActions } from '../../actions/password-reset/PasswordResetSaveActions';
import { API_STATUSES } from '../../constants';
import { IApiError } from '../../errors';
import { ImmutableMap } from '../../typings';

interface IPasswordState {
    value: string;
    valid: boolean;
    error: string;
}

export interface IPasswordResetSaveState {
    urlParams: {
        token: string | undefined;
        email: string | undefined;
        valid: string | undefined;
    };
    password: IPasswordState;
    passwordConfirmation: IPasswordState;
    submit: {
        status: string;
        error: IApiError;
        showError: boolean;
    };
}

export type ImmutablePasswordResetSaveState =
    ImmutableMap<IPasswordResetSaveState>;

interface IPasswordResetSaveContextValue {
    state: ImmutablePasswordResetSaveState;
    dispatch: React.Dispatch<PasswordResetSaveActions>;
}

const initialState: IPasswordResetSaveState = {
    urlParams: {
        token: undefined,
        email: undefined,
        valid: undefined,
    },
    password: {
        value: '',
        valid: false,
        error: null,
    },
    passwordConfirmation: {
        value: '',
        valid: false,
        error: null,
    },
    submit: {
        status: API_STATUSES.IDLE,
        error: null,
        showError: false,
    },
};

export const INITIAL_PASSWORD_RESET_SAVE_FORM_STATE = Map(initialState);

const defaultValue: IPasswordResetSaveContextValue = {
    state: INITIAL_PASSWORD_RESET_SAVE_FORM_STATE,
    dispatch: () => null,
};

const PasswordResetSaveContext =
    createContext<IPasswordResetSaveContextValue>(defaultValue);

export default PasswordResetSaveContext;
