import { useContext } from 'react';

import i18n from '@nextroll/ar-i18n';
// @ts-ignore - no types for ar-style-base
import { Logo } from '@nextroll/ar-style-base';

import { MARKETING_WEBSITE_URLS } from '../constants';
import ThemeWrapperContext from '../contexts/ThemeWrapperContext';

const Header = () => {
    const { businessUnit } = useContext(ThemeWrapperContext);

    return (
        <div className='entryhall-header'>
            <a href={MARKETING_WEBSITE_URLS[businessUnit]}>
                <Logo i18n={i18n} businessUnit={businessUnit} height='30px' />
            </a>
        </div>
    );
};

export default Header;
