import { useReducer } from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import AppTFASetupContext, {
    INITIAL_APP_TFA_SETUP_STATE,
} from '../../contexts/tfa-setup/AppTFASetupContex';
import AppTFASetupForm from '../../forms/tfa-setup/AppTFASetupForm';
import AppTFASetupReducer from '../../reducers/tfa-setup/AppTFASetupReducer';

const AppTFASetupPage = () => {
    const [state, dispatch] = useReducer(
        AppTFASetupReducer,
        INITIAL_APP_TFA_SETUP_STATE
    );

    return (
        <AppTFASetupContext.Provider value={{ state, dispatch }}>
            <section className='entryhall-page-app-tfa-setup'>
                <Header />
                <AppTFASetupForm />
                <Footer />
            </section>
        </AppTFASetupContext.Provider>
    );
};

export default AppTFASetupPage;
