import {
    ACTION_TYPES,
    SignInActions,
} from '../../actions/sign-in/SignInActions';
import { ImmutableSignInState } from '../../contexts/sign-in/SignInContext';

const SignInReducer = (
    state: ImmutableSignInState,
    action: SignInActions
): ImmutableSignInState => {
    switch (action.type) {
        case ACTION_TYPES.SIGN_IN_SET_EMAIL:
            return state
                .set('email', action.payload)
                .setIn(['submit', 'showError'], false);
        case ACTION_TYPES.SIGN_IN_SET_PASSWORD:
            return state
                .set('password', action.payload)
                .setIn(['submit', 'showError'], false);
        case ACTION_TYPES.SIGN_IN_SET_NEXT:
            return state.set('next', action.payload.value);
        case ACTION_TYPES.SIGN_IN_SUBMIT_IN_PROGRESS:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'redirect'], null)
                .setIn(['submit', 'to'], null)
                .setIn(['submit', 'error'], null)
                .setIn(['submit', 'showError'], false);
        case ACTION_TYPES.SIGN_IN_SUBMIT_ERROR:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'redirect'], null)
                .setIn(['submit', 'to'], null)
                .setIn(['submit', 'error'], action.payload.error)
                .setIn(['submit', 'showError'], true);
        case ACTION_TYPES.SIGN_IN_SUBMIT_SUCCESS:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'redirect'], action.payload.redirect)
                .setIn(['submit', 'to'], action.payload.to)
                .setIn(['submit', 'error'], null)
                .setIn(['submit', 'showError'], false);
    }
};

export default SignInReducer;
