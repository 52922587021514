import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import i18n from '@nextroll/ar-i18n';

import ThemeWrapperContext from '../../contexts/ThemeWrapperContext';

const PasswordResetSaveInvalid = () => {
    const { supportEmail } = useContext(ThemeWrapperContext);
    const navigate = useNavigate();
    const onClick = () => navigate('../lostpassword');

    return (
        <div className='entryhall-card-2 entryhall-feedback-password-reset-save-invalid'>
            <div className='entryhall-card-header'>
                <p className='entryhall-card-header-title'>
                    <i className='fas ar-icon-danger' />
                    <span>{i18n.gettext('There has been an error!')}</span>
                </p>
            </div>
            <div className='entryhall-card-body'>
                <p>
                    {i18n.gettext('Please try resetting your password again.')}
                </p>
                <p>
                    {i18n.gettext(
                        'If you continue having issues please reach out to <LINK>{{supportEmailAddress}}</LINK><br />or your Account Executive.',
                        {
                            LINK: (
                                <a
                                    href={`mailto:${supportEmail}`}
                                    target='_blank'
                                />
                            ),
                            supportEmailAddress: supportEmail,
                        }
                    )}
                </p>
            </div>
            <div className='entryhall-card-footer'>
                <button
                    type='button'
                    className='btn btn-primary'
                    onClick={onClick}
                >
                    {i18n.gettext('Reset Password')}
                </button>
            </div>
        </div>
    );
};

export default PasswordResetSaveInvalid;
