import Footer from '../../components/Footer';
import Header from '../../components/Header';
import LockedAccount from '../../components/sign-in/LockedAccount';

const LockedAccountPage = () => {
    return (
        <section className='entryhall-page-locked-account'>
            <Header />
            <LockedAccount />
            <Footer />
        </section>
    );
};

export default LockedAccountPage;
