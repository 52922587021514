import { Map } from 'immutable';
import { createContext } from 'react';

import { PasswordResetInitActions } from '../../actions/password-reset/PasswordResetInitActions';
import { API_STATUSES } from '../../constants';
import { IApiError } from '../../errors';
import { ImmutableMap } from '../../typings';

export interface IPasswordResetInitState {
    email: {
        value: string;
        valid: boolean;
        error: React.ReactNode;
    };
    submit: {
        status: string;
        error: IApiError;
    };
}

export type ImmutablePasswordResetInitState =
    ImmutableMap<IPasswordResetInitState>;

interface IPasswordResetInitContextValue {
    state: ImmutablePasswordResetInitState;
    dispatch: React.Dispatch<PasswordResetInitActions>;
}

const initialState: IPasswordResetInitState = {
    email: {
        value: '',
        valid: true,
        error: null,
    },
    submit: {
        status: API_STATUSES.IDLE,
        error: null,
    },
};

export const INITIAL_PASSWORD_RESET_INIT_FORM_STATE = Map(initialState);

const defaultValue: IPasswordResetInitContextValue = {
    state: INITIAL_PASSWORD_RESET_INIT_FORM_STATE,
    dispatch: () => null,
};

const PasswordResetInitContext =
    createContext<IPasswordResetInitContextValue>(defaultValue);

export default PasswordResetInitContext;
