import { Map } from 'immutable';
import { createContext } from 'react';

import { ISetInputValuePayload } from '../../actions/common';
import { TFAActions } from '../../actions/sign-in/TFAActions';
import { API_STATUSES } from '../../constants';
import { IApiError } from '../../errors';
import { ImmutableMap } from '../../typings';

interface ICodeState extends ISetInputValuePayload {
    value: string;
}

export interface ITFAState {
    code: ICodeState;
    rememberDevice: boolean;
    alreadySubmittedOnce: boolean;
    init_status: {
        status: string;
        methods: string[];
        contacts: {
            email: string;
            phone: string;
        };
        redirect: string;
        to: string;
    };
    sms: {
        status: string;
        error: IApiError;
        showError: boolean;
    };
    email: {
        status: string;
        error: IApiError;
        showError: boolean;
    };
    submit: {
        status: string;
        error: IApiError;
        showError: boolean;
        redirect: string;
        to: string;
    };
}

export type ImmutableTFAState = ImmutableMap<ITFAState>;

interface ITFAContextValue {
    state: ImmutableTFAState;
    dispatch: React.Dispatch<TFAActions>;
}

export const initialState: ITFAState = {
    code: {
        value: '',
        valid: true,
        error: null,
    },
    rememberDevice: false,
    alreadySubmittedOnce: false,
    init_status: {
        status: API_STATUSES.IDLE,
        methods: null,
        contacts: {
            email: null,
            phone: null,
        },
        redirect: null,
        to: null,
    },
    sms: {
        status: API_STATUSES.IDLE,
        error: null,
        showError: false,
    },
    email: {
        status: API_STATUSES.IDLE,
        error: null,
        showError: false,
    },
    submit: {
        status: API_STATUSES.IDLE,
        error: null,
        showError: false,
        redirect: null,
        to: null,
    },
};

export const INITIAL_TFA_FORM_STATE = Map(initialState);

const defaultValue: ITFAContextValue = {
    state: INITIAL_TFA_FORM_STATE,
    dispatch: () => null,
};

const TFAContext = createContext<ITFAContextValue>(defaultValue);

export default TFAContext;
