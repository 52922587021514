import { Map } from 'immutable';
import { createContext } from 'react';

import { ISetInputValuePayload } from '../../actions/common';
import { SamlSignInActions } from '../../actions/sign-in/SamlSignInActions';
import { API_STATUSES } from '../../constants';
import { IApiError } from '../../errors';
import { ImmutableMap } from '../../typings';

interface IEmailState extends ISetInputValuePayload {
    value: string;
}

export interface ISamlSignInState {
    email: IEmailState;
    next: string;
    submit: {
        status: string;
        error: IApiError;
        showError: boolean;
        redirect: string;
        to: string;
    };
}

export type ImmutableSamlSignInState = ImmutableMap<ISamlSignInState>;

interface ISamlSignInContextValue {
    state: ImmutableSamlSignInState;
    dispatch: React.Dispatch<SamlSignInActions>;
}

const initialState: ISamlSignInState = {
    email: {
        value: '',
        valid: true,
        error: null,
    },
    next: '',
    submit: {
        status: API_STATUSES.IDLE,
        error: null,
        showError: false,
        redirect: null,
        to: null,
    },
};

export const INITIAL_SAML_SIGN_IN_FORM_STATE = Map(initialState);

const defaultValue: ISamlSignInContextValue = {
    state: INITIAL_SAML_SIGN_IN_FORM_STATE,
    dispatch: () => null,
};

const SamlSignInContext = createContext<ISamlSignInContextValue>(defaultValue);

export default SamlSignInContext;
