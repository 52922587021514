import { Map } from 'immutable';
import { createContext } from 'react';

import { SmsTFASetupActions } from '../../actions/tfa-setup/SmsTFASetupActions';
import { API_STATUSES } from '../../constants';
import { IApiError } from '../../errors';
import { ImmutableMap } from '../../typings';

export interface IPhoneNumberValuePayload {
    value: string;
    valid: boolean;
    errors: React.ReactNode[];
}

export interface ISmsTFASetupState {
    phone: IPhoneNumberValuePayload;
    submit: {
        status: string;
        error: IApiError;
        showError: boolean;
    };
}

export type ImmutableSmsTFASetupState = ImmutableMap<ISmsTFASetupState>;

interface ISmsTFASetupContextValue {
    state: ImmutableSmsTFASetupState;
    dispatch: React.Dispatch<SmsTFASetupActions>;
}

const initialState: ISmsTFASetupState = {
    phone: {
        value: '',
        valid: true,
        errors: [],
    },
    submit: {
        status: API_STATUSES.IDLE,
        error: null,
        showError: false,
    },
};

export const INITIAL_SMS_TFA_SETUP_FORM_STATE = Map(initialState);

const defaultValue: ISmsTFASetupContextValue = {
    state: INITIAL_SMS_TFA_SETUP_FORM_STATE,
    dispatch: () => null,
};

const SmsTFASetupContext =
    createContext<ISmsTFASetupContextValue>(defaultValue);

export default SmsTFASetupContext;
