// External modules.
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import 'core-js/stable';
import { render } from 'react-dom';

// NextRoll modules.
// @ts-ignore - no types for telemetry
import telemetry from '@nextroll/telemetry';

// Components.
import Root from './components/Root';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://8b237d3eacc14e2c9dd03726ae99789f@o1899.ingest.sentry.io/5821690',
        environment: process.env.ENVIRONMENT,
        release: process.env.RELEASE,
        beforeSend: (event, hint) => {
            if (event.level === 'error' && hint.originalException) {
                datadogRum.addError(hint.originalException, event.tags);
            }
            return event;
        },
    });

    datadogRum.init({
        applicationId: 'd13fcf22-1d84-4705-aa02-5ce4c1c91602',
        clientToken: 'pub26749747cea0618ec4e6b96c41048061',
        site: 'datadoghq.com',
        service: 'entry-hall',
        env: process.env.ENVIRONMENT,
        version: process.env.RELEASE,
        sampleRate: process.env.ENVIRONMENT === 'production' ? 100 : 10,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingOrigins: [`https://${window.location.hostname}`],
    });

    datadogRum.startSessionReplayRecording();
}

const initTelemetry = async () => {
    await telemetry.load({
        heap: true,
        segment: true,
        env: process.env.TELEMETRY_ENV,
    });
};

// We wrap the app render in a separate function to allow us to conditionally render the app based on the current
// environment.
export const renderApp = () => {
    initTelemetry();

    // Render <Root />.
    return render(<Root />, document.getElementById('app'));
};

// Do not render app when we're testing. The corresponding test for this file will take care of rendering itself to
// be able to test the rendering logic in isolation.
if (process.env.NODE_ENV !== 'test') {
    renderApp();
}
