import { useReducer } from 'react';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import SignInContext, {
    INITIAL_SIGN_IN_FORM_STATE,
} from '../../contexts/sign-in/SignInContext';
import SignInForm from '../../forms/sign-in/SignInForm';
import SignInReducer from '../../reducers/sign-in/SignInReducer';

const SignInPage = () => {
    const [state, dispatch] = useReducer(
        SignInReducer,
        INITIAL_SIGN_IN_FORM_STATE
    );

    return (
        <SignInContext.Provider value={{ state, dispatch }}>
            <section className='entryhall-page-sign-in'>
                <Header />
                <SignInForm />
                <Footer />
            </section>
        </SignInContext.Provider>
    );
};

export default SignInPage;
